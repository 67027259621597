<template>
  <v-list-item :to="layer.showURL(version)" exact :class="layerClass" data-testid="annotation-layer-list-item">
    <v-list-item-content>
      <v-list-item-title>{{ layer.name }}</v-list-item-title>
    </v-list-item-content>
    <ListActionIndicator :item="layer" />
    <v-list-item-action v-if="showShareButton">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" :to="layer.showURL(version, true)" small text icon data-testid="share-button" v-on="on">
            <v-icon small>{{ shareButtonIcon }}</v-icon>
          </v-btn>
        </template>
        <span>{{ shareButtonTooltipText }}</span>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-action v-else-if="layer.is_legacy_layer">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" small text icon v-on="on">
            <v-icon small>mdi-information-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('share_tooltip_legacy') }}</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'LayerListItem',
  props: {
    layer: {
      type: Object,
      required: true,
    },
    version: {
      type: Object,
      required: true,
    },
  },
  computed: {
    layerClass() {
      const classes = []
      if (this.$route.query.layer_id && this.layer.id.toString() === this.$route.query.layer_id.toString()) {
        classes.push('primary--text')
      }
      if (this.layer.is_private_layer) {
        classes.push('blue lighten-5')
      }
      return classes.join(' ')
    },
    showShareButton() {
      return this.features.shareAnnotations && this.layer.is_owner && !this.layer.is_legacy_layer
    },
    shareButtonIcon() {
      if (this.layer.is_private_layer) {
        return 'mdi-share-variant'
      }
      return 'mdi-pencil'
    },
    shareButtonTooltipText() {
      if (this.layer.is_private_layer) {
        return this.$t('share_tooltip_text')
      }
      return this.$t('edit_share_tooltip_text')
    },
  },
}
</script>
