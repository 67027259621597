<template>
  <v-list-item dense :ripple="clickable" :to="to" color="primary">
    <slot />
  </v-list-item>
</template>

<script>
export default {
  name: 'LinkedListItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    to() {
      if (!this.clickable) {
        return null
      }
      return this.item.url
    },
  },
}
</script>
