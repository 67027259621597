<template>
  <BaseLayout :breadcrumbs="breadcrumbs">
    <AgendaItemsSidebar slot="drawer-left" :scroll-to="agendaItem" />
    <v-row>
      <v-col cols="12">
        <TitledCard v-if="agendaItem.proposal" :title="$t('Documents')">
          <!-- Show proposal when proposal is present -->
          <h3 class="body-2">{{ $t('agenda_item_proposal') }}</h3>
          <v-list data-testid="proposal-list" two-line class="py-0">
            <v-list-item
              :disabled="!agendaItem.hasProposal"
              :to="agendaItem.proposal.url"
              data-testid="proposal-list-item"
            >
              <v-list-item-avatar class="rounded-0">
                <v-icon>{{ `${agendaItem.proposal.mimeTypeIcon}` }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('agenda_item_show_proposal') }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{
                    $t('last_update_on', {
                      date: toLocaleDateTimeString(agendaItem.proposal.updated),
                    })
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <ListActionIndicator :item="agendaItem.proposal" />
            </v-list-item>
          </v-list>
          <h3 class="body-2 mt-3">{{ $t('Attachments') }}</h3>
          <AttachmentList :items="agendaItem.attachments" />
        </TitledCard>
        <TitledCard v-else :title="$t('Documents')">
          <!-- Show flat documents when no proposal -->
          <AttachmentList v-if="agendaItem.attachments.length" :items="agendaItem.attachments" />
          <p v-else class="grey--text text--darken-1">{{ $t('no_documents_existing') }}</p>
          <template slot="actions">
            <v-btn
              v-if="features.shareAnnotations"
              :to="agendaItem.showURL({ share_agenda_item: true })"
              text
              color="primary"
              class="shrink"
              :disabled="!agendaItem.attachments.length"
              >{{ $t('share_agenda_item') }}</v-btn
            >
          </template>
        </TitledCard>
      </v-col>
      <v-col cols="12">
        <TitledCard :title="$t('personal_documents')" collapsable>
          <DeleteableDocumentList
            :items="agendaItem.personal_documents"
            :deleteable="true"
            empty-message=""
            data-testid="personal-document-list"
          />
          <h3 class="font-weight-medium mt-4">{{ $t('shared_with_me') }}</h3>
          <v-list data-testid="shared-personal-document-list">
            <v-list-item
              v-for="item in sharedPersonalDocuments"
              :key="`shared-personal-document${item.id}`"
              :to="{
                name: 'personaldocument',
                params: { id: item.id },
              }"
            >
              <v-list-item-avatar class="rounded-0">
                <v-icon>$vuetify.icons.pdf</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-wrap">{{ item.title }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('updated_last_on', { date: toLocaleDateTimeString(item.updated) }) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <Upload
            data-testid="personal-doc-upload-button"
            accept="application/pdf"
            file-extension="pdf"
            :title="$t('add_personal_document')"
            :label="$t('personal_document')"
            :error-message="$t('error_on_document_upload')"
            :url="personalDocumentUploadURL"
            field="file"
            @canceled="documentName = ''"
            @uploaded="onPersonalDocumentUploadSuccess"
            @fileAttached="personaldocumentFileAttached"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" text outlined color="primary" class="mt-2" v-on="on">
                {{ $t('add_document') }}
              </v-btn>
            </template>
            <v-text-field v-model="documentName" name="title" :label="$t('Title')"> </v-text-field>
          </Upload>
        </TitledCard>
      </v-col>
      <v-col cols="12">
        <NotesList :notes="notes" @create="createNote" />
      </v-col>
    </v-row>
    <Download :url="downloadURL" />
  </BaseLayout>
</template>

<script>
import path from 'path'
import flatten from 'lodash/flatten'
import orderBy from 'lodash/orderBy'
import joinPath from 'path.join'
import { toLocaleDateTimeString } from '@/filters'
import { createLink } from '@/api/helpers'
import AgendaItem from '@/store/models/agendaitem'
import Committee from '@/store/models/committee'
import Meeting from '@/store/models/meeting'
import Note from '@/store/models/note'
import Attachment from '@/store/models/attachment'
import Proposal from '@/store/models/proposal'
import PersonalDocument from '@/store/models/personaldocument'

const downloadURLtemplate = '/committees/{committee}/meetings/{meeting}/agenda_items/{agendaItem}/download/'
const uploadPersonalDocumentTemplate = '/agenda_items/{agendaItem}/create_personal_document/'
const { VUE_APP_API_ROOT, BASE_URL } = process.env

export default {
  name: 'AgendaItem',
  async initialAsyncData({ id }) {
    const agendaItem = await AgendaItem.findOrFetch(id)
    const meeting = await Meeting.findOrFetch(agendaItem.meeting_id)
    await Promise.all([Committee.findOrFetch(meeting.committee_id), AgendaItem.fetchAllByModel(meeting)])
  },
  async asyncData({ id }) {
    const agendaItem = await AgendaItem.findOrFetch(id)
    const meeting = await Meeting.findOrFetch(agendaItem.meeting_id)
    PersonalDocument.deleteAll()
    await Promise.all([
      Note.fetchAllByModel(agendaItem),
      Attachment.fetchAllByModel(agendaItem),
      Proposal.fetchAllByModel(meeting),
      PersonalDocument.fetchAllByModel(agendaItem),
    ])
  },
  data() {
    return {
      documentName: '',
      sharedPersonalDocuments: [],
    }
  },
  computed: {
    breadcrumbs() {
      const agendaItem = AgendaItem.query().with('meeting.committee').find(Number.parseInt(this.$route.params.id, 10))
      return [agendaItem.meeting.committee, agendaItem.meeting, agendaItem]
    },
    agendaItem() {
      return AgendaItem.query()
        .with('notes')
        .with('attachments.annotations')
        .with('proposal.annotations')
        .with('meeting.committee')
        .with('personal_documents')
        .find(this.$route.params.id)
    },
    downloadURL() {
      return joinPath(
        BASE_URL,
        VUE_APP_API_ROOT,
        createLink(downloadURLtemplate, {
          committee: this.agendaItem.meeting.committee.id,
          meeting: this.agendaItem.meeting.id,
          agendaItem: this.agendaItem.id,
        }),
      )
    },
    notes() {
      const { proposal } = this.agendaItem
      return orderBy(
        [
          ...(this.agendaItem.notes || []), // Agendaitem notes
          ...flatten(this.agendaItem.attachments.map((a) => a.annotations || [])), // Attachment notes
          ...(proposal ? proposal.annotations || [] : []), // Proposal notes
        ],
        (note) => new Date(note.created),
        'desc',
      )
    },
    personalDocumentUploadURL() {
      return createLink(uploadPersonalDocumentTemplate, { agendaItem: this.agendaItem.id })
    },
  },
  async mounted() {
    this.sharedPersonalDocuments = await this.agendaItem.fetchSharedPersonalDocuments()
  },
  methods: {
    toLocaleDateTimeString,
    createNote(note) {
      this.agendaItem.createNote(note)
    },
    onPersonalDocumentUploadSuccess() {
      PersonalDocument.fetchAllByModel(this.agendaItem)
    },
    personaldocumentFileAttached(file) {
      if (!this.documentName.length) {
        this.documentName = path.basename(file.name, '.pdf')
      }
    },
  },
  head() {
    return {
      title() {
        return { inner: this.agendaItem.title }
      },
    }
  },
}
</script>
