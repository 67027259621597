import compact from 'lodash/compact'
import joinPath from 'path.join'
import { api } from '@/api'
import { createLink } from '@/api/helpers'
import Base from './base'

export default class User extends Base {
  static entity = 'user'

  static apiPath = 'users'

  static fields() {
    return {
      id: this.attr(null),
      username: this.string(''),
      email: this.string(''),
      last_name: this.string(''),
      first_name: this.string(''),
      read_documents: this.boolean(null),
    }
  }

  get fullName() {
    return compact([this.last_name, this.first_name]).join(' ')
  }

  get displayName() {
    return this.fullName || this.username
  }

  get mailToLink() {
    return `mailto:${this.email}`
  }

  static async readDocumentUsers(meeting) {
    const url = createLink(joinPath(meeting.idPath, 'read_documents_users'))
    const { data } = await api.get(url)
    return this.dispatch('create', { data })
  }
}
