<template>
  <div ref="pdfViewerContainer" style="height: calc(100vh - 64px)" />
</template>

<script>
import PSPDFKit from 'pspdfkit'
import joinPath from 'path.join'
import { mapActions, mapGetters } from 'vuex'
import { fetchDocument } from '@/api'

const { BASE_URL, VUE_APP_PSPDFKIT_LICENSE_KEY } = process.env

export default {
  name: 'PSPDFKITViewer',
  props: {
    annotateOn: {
      type: Object,
      default: () => {},
    },
  },
  viewer: null,
  computed: {
    ...mapGetters({
      annotationPresets: 'pdfviewer/getAnnotationPresets',
    }),
  },
  watch: {
    $route: {
      handler() {
        this.load()
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.unload()
  },
  methods: {
    ...mapActions({
      updateAnnotationPresets: 'pdfviewer/updateAnnotationPresets',
      setAnnotationPresets: 'pdfviewer/setAnnotationPresets',
    }),
    async fetchDocument() {
      const { file: src } = this.annotateOn
      const { data: pdf } = await fetchDocument(src)
      return pdf
    },
    async load() {
      this.unload()
      try {
        const [pdf, layerAnnotations] = await Promise.all([
          this.fetchDocument(),
          this.annotateOn.fetchAnnotations(this.$route.query.layer_id),
        ])

        const pdfConfig = {
          document: pdf,
          container: this.$refs.pdfViewerContainer,
          baseUrl: `${document.location.origin}${this.getBaseURL()}`,
        }
        if (VUE_APP_PSPDFKIT_LICENSE_KEY) {
          pdfConfig.licenseKey = VUE_APP_PSPDFKIT_LICENSE_KEY
        }
        if (layerAnnotations.xfdf_string) {
          pdfConfig.XFDF = layerAnnotations.xfdf_string
          pdfConfig.toolbarItems = [] // hide annotation toolbar.
        } else {
          pdfConfig.instantJSON = {
            format: 'https://pspdfkit.com/instant-json/v1',
            annotations: layerAnnotations.annotations,
          }
        }
        const instance = await PSPDFKit.load(pdfConfig)
        this.viewer = instance

        instance.setAnnotationPresets(this.annotationPresets)

        // Hide image and stamp annotations because backend does not properly implement this feature
        instance.setToolbarItems((items) => {
          return items.filter((i) => !['image', 'stamp'].includes(i.type))
        })

        instance.addEventListener('annotationPresets.update', ({ currentPreset, newPresetProperties }) => {
          this.updateAnnotationPresets({ currentPreset, newPresetProperties })
        })

        if (!layerAnnotations.xfdf_string) {
          instance.addEventListener('annotations.create', (createdAnnotations) => {
            const annotation = createdAnnotations.first()
            const serializedObject = PSPDFKit.Annotations.toSerializableObject(annotation)
            this.annotateOn.createOrUpdateAnnotation(this.$route.query.layer_id, serializedObject)
          })
          instance.addEventListener('annotations.update', (updatedAnnotations) => {
            const annotation = updatedAnnotations.first()
            const serializedObject = PSPDFKit.Annotations.toSerializableObject(annotation)
            this.annotateOn.createOrUpdateAnnotation(this.$route.query.layer_id, serializedObject)
          })
          instance.addEventListener('annotations.delete', (deletedAnnotations) => {
            const annotation = deletedAnnotations.first()
            this.annotateOn.deleteAnnotation(annotation.id)
          })
        }
      } catch (error) {
        console.error(error)
        PSPDFKit.unload(this.$refs.pdfViewerContainer)
      }
    },
    unload() {
      if (this.viewer) {
        PSPDFKit.unload(this.viewer)
        this.viewer = null
      }
    },
    getBaseURL() {
      if (!BASE_URL) {
        return '/'
      }
      // Make sure we have leading and trailing slashes
      return joinPath(`/${BASE_URL}/`)
    },
  },
}
</script>

<style></style>
