<template>
  <BaseLayout narrow>
    <AttachmentSidebar slot="drawer-left" :attachment="documentVersion" />
    <template v-slot:toolbar-actions>
      <PdfViewerActions :document="documentVersion" />
    </template>
    <PdfViewer v-if="hasVersionId" :annotate-on="documentVersion">
      <Download :url="documentVersion.file" />
    </PdfViewer>
    <VersionsSidebar slot="drawer-right" :versionable="documentVersion" show-next-agenda-item-link />
  </BaseLayout>
</template>

<script>
import AgendaItem from '@/store/models/agendaitem'
import Attachment from '@/store/models/attachment'
import Proposal from '@/store/models/proposal'
import Note from '@/store/models/note'
import documentMixin from '@/components/pages/mixins/document'

export default {
  name: 'Attachment',
  mixins: [documentMixin],
  async asyncData({ id, version_id: versionId }) {
    const attachment = await Attachment.fetchVersion(id, versionId)
    const [versions, agendaItem] = await Promise.all([
      attachment.fetchVersions(),
      AgendaItem.findOrFetch(attachment.agenda_item_id),
    ])
    await Promise.all([
      ...versions.map((v) => v.fetchAnnotationLayers()),
      Attachment.fetchAllByModel(agendaItem),
      Note.fetchAllByModel(attachment),
    ])
    if (agendaItem.proposal_id) {
      await Proposal.fetch(agendaItem.proposal_id)
    }
  },
  data() {
    return {
      documentType: 'attachment',
    }
  },
  computed: {
    document() {
      return Attachment.query().with('annotations').with('agenda_item.attachments').with('agenda_item.proposal')
    },
  },
  updated() {
    this.$emit('updateHead')
  },
}
</script>
