<template>
  <v-autocomplete
    ref="main"
    autofocus
    text
    filled
    hide-details
    color="white"
    prepend-inner-icon="mdi-magnify"
    :placeholder="`${$t('Search')}…`"
    :style="{ 'max-width': '500px', 'min-width': $vuetify.breakpoint.xl ? '500px' : '150px' }"
    :items="items"
    :search-input.sync="query"
    :no-data-text="$t('no_search_results')"
    no-filter
    return-object
    :loading="loading"
    clearable
    :value="selectedResult"
    :hide-no-data="loading || !query"
    data-testid="livesearch"
    dense
    class="search-input"
    @keyup.enter="openSearchView"
  >
    <template v-slot:item="{ item: { text, type, highlightings, breadcrumbs, value }, on, attrs }">
      <v-list-item v-bind="attrs" @click="openSearchResult(type, value)" v-on="on">
        <v-list-item-avatar size="24" class="mt-3">
          <v-icon class="search-result-icon blue-grey--text text--lighten-1">{{ getIconForType(type) }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle class="blue-grey--text text--lighten-1 caption">
            {{ breadcrumbs }}
          </v-list-item-subtitle>
          <v-list-item-title
            class="blue-grey--text text--darken-3 body-2"
            v-html="(highlightings.title && highlightings.title[0]) || text"
          />
          <template v-if="showSearchableText(type)">
            <v-list-item-subtitle
              v-for="(highlighting, index) in highlightings.searchable_text"
              :key="`highlighting-${index}`"
              class="blue-grey--text text--darken-3 caption search-result-searchable-text"
              v-html="highlighting"
            />
          </template>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapActions } from 'vuex'
import { api } from '@/api'
import { createLink } from '@/api/helpers'

const typeIconMap = {
  'backend.meeting': 'mdi-calendar-today',
  'backend.agendaitem': 'mdi-folder',
  'backend.committee': 'mdi-account-group',
  'backend.attachment': 'mdi-file',
  'backend.document': 'mdi-file',
  'backend.proposal': 'mdi-file',
  'backend.protocol': 'mdi-file',
  'backend.committeedocument': 'mdi-file',
  'backend.meetingdocument': 'mdi-file',
}

export default {
  name: 'LiveSearch',
  data() {
    return {
      query: null,
      loading: false,
      items: [],
      selectedResult: undefined,
    }
  },
  watch: {
    query(value) {
      if (value === null) {
        return
      }
      this.search(value)
    },
  },
  methods: {
    ...mapActions('application', ['setCurrentFullPath']),
    async openSearchResult(type, value) {
      if (!value) {
        this.items = []
        return
      }
      if (type === 'backend.committeedocument') {
        window.open(value, '_blank')
      } else {
        this.$router.push(value)
      }
      this.$refs.main.clearableCallback()
      await this.$nextTick()
      this.$refs.main.blur()
    },
    getIconForType(type) {
      return typeIconMap[type] || ''
    },
    showSearchableText(type) {
      return ['attachment', 'committeedocument', 'document', 'proposal', 'protocol'].includes(type)
    },
    debouncedSearch: debounce(async function search(query) {
      try {
        const {
          data: { results },
        } = await api.get(createLink('search'), { params: { query } })
        this.items = results.map((d) => ({
          text: d.title,
          value: d.path,
          type: d.type,
          breadcrumbs: d.breadcrumbs,
          highlightings: d.highlighting,
        }))
      } catch (error) {
        this.notifyError(this.$t('error_while_searching'))
        throw error
      } finally {
        this.loading = false
      }
    }, 200),
    openSearchView() {
      this.setCurrentFullPath(this.$route.fullPath)
      this.$router.push({
        name: 'search',
        query: { query: this.query },
      })
    },
    search(query) {
      this.loading = true
      this.debouncedSearch(query)
    },
  },
}
</script>
<style lang="scss">
.search-input {
  max-width: 625px !important;

  &.v-input--is-focused {
    background-color: rgba(#fff, 0.1) !important;
  }

  .v-input__slot {
    border: none !important;
    background-color: transparent !important;

    &:hover {
      background-color: rgba(#fff, 0.1) !important;
    }
  }

  .v-input__append-inner:nth-child(3) {
    display: none;
  }
}
</style>
