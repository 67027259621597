const messages = {
  de: {
    shared_with_me: 'Mit mir geteilte Dokumente',
    appName: 'Sitzungs-App',
    bookmark: 'Merken',
    note: '1 Notiz | {count} Notizen',
    forthcoming_meetings: 'Aktuelle Sitzungen',
    current_meeting: 'Aktuelle Sitzung',
    next_meeting: 'Nächste Sitzung',
    currently_no_forthcoming_meetings: 'Momentan sind keine Sitzungen geplant.',
    members: '1 Mitglied | {count} Mitglieder',
    Search: 'Suche',
    basic_document: 'Grundlagendokument',
    basic_documents: 'Grundlagendokumente',
    add_basic_document: 'Grundlagendokument hinzufügen',
    error_on_basic_document_upload: 'Beim Hochladen des Grundlagendokuments ist ein Fehler aufgetreten.',
    add_document: 'Dokument hinzufügen',
    meetings: 'Meetings',
    meetingCalendar: 'Sitzungskalender',
    one_meeting: 'eine Sitzung',
    multiple_meetings: 'mehrere Sitzungen',
    notes: 'Notizen',
    post_proposal: 'Vorstoss einreichen',
    post_suggestion: 'Antrag einreichen',
    member_can_post_proposals: 'Als Mitglied dieses Gremiums können Sie einen Vorstoss einreichen.',
    member_can_post_suggestions: 'Als Mitglied dieses Gremiums können Sie einen Antrag einreichen.',
    meeting_from: 'Sitzung vom {date}',
    protocol: 'Protokoll',
    protocols: 'Protokolle',
    show_protocol: 'Protokoll anzeigen',
    Protocol_of: 'Protokoll vom {date}',
    download_meeting: 'Sitzung herunterladen',
    agenda_items: 'Traktanden',
    no_notes_existing: 'keine Notizen vorhanden',
    agenda_item_show_proposal: 'Antrag anzeigen',
    note_placeholder: 'Notiz ...',
    Add: 'Hinzufügen',
    Cancel: 'Abbrechen',
    Scaling_down: 'Verkleinern',
    Page_width: 'Seitenbreite',
    Full_page: 'Ganze Seite',
    Scaling_up: 'Vergrössern',
    mark: 'Markieren',
    Add_note: 'Notiz hinzufügen',
    Show_note: 'Notizen anzeigen',
    Logout: 'Abmelden',
    for_committee: 'für Gremium',
    only_for_me: 'nur für mich',
    visibility: 'Sichtbarkeit',
    add_note_here: 'Notiz hier erfassen',
    create_shortcut: 'Windows: Ctrl + Enter  |  OS X: CMD + Enter zum direkten hinzufügen.',
    save_shortcut: 'Windows: Ctrl + Enter  | OS X: CMD + Enter zum direkten speichern.',
    Save: 'Speichern',
    Note_in_context: 'Notiz im Kontext',
    Committee: 'Gremium',
    hint_visible_for_committee: 'Diese Notiz ist für alle im Gremium Beteiligten einsehbar.',
    Only_me: 'Nur ich',
    hint_visible_only_for_me: 'Diese persönliche Notiz ist ausschliesslich für mich ersichtlich.',
    this_note_is_outdated: 'Diese Notiz ist veraltet.',
    Edit: 'Bearbeiten',
    Remove: 'Löschen',
    Removed_notes: 'Entfernte Notizen',
    hint_outdated_note:
      'Diese Notiz wurde zu einem Traktandum verfasst, welches unterdessen nicht mehr Teil einer Sitzung ist.',
    hint_copy_outdated_note:
      'Sollte diese Notiz weiterhin benötigt werden, können Sie den Text kopieren und in einer neuen Notiz festhalten.',
    close: 'schliessen',
    Close: 'Schliessen',
    no_attachments_exist: 'Keine Anhänge vorhanden',
    Attachments: 'Anhänge',
    Documents: 'Dokumente',
    agenda_item_proposal: 'Antrag',
    agenda_item_proposals: 'Anträge',
    Note: 'Notizen',
    Send_notification: 'Benachrichtigung versenden',
    notification_description:
      'Hier können Sie ausgewählte Teilnehmende über die Aufschaltung, eine Änderung oder sonstige Ereignisse zur Sitzung informieren. Die ausgewählten Personen erhalten eine E-Mail mit dem Link zur Sitzung. Zusätzlich können Sie eine eigene Nachricht im Textfeld erfassen, diese wird im Mail dargestellt.',
    add_notification_text_here: 'Benachrichtigungstext hier einfügen',
    Send: 'Senden',
    Add_date: 'Datum einfügen',
    Add_title: 'Titel einfügen',
    Automatic_Message: 'Automatische Nachricht',
    No_preview_available: 'Für dieses Dokument ist keine Vorschau verfügbar.',
    Select: 'Auswählen',
    Create: 'Erstellen',
    Update: 'Aktualisieren',
    last_update_on: 'Zuletzt aktualisiert um {date}',
    updated_last_on: 'Zuletzt am {date} aktualisiert',
    no_committees_existing: 'Keine Gremien vorhanden',
    no_basic_documents_existing: 'Keine Grundlagendokumente vorhanden',
    no_users_existing: 'Keine Benutzer vorhanden',
    user_not_assigned_to_a_committee: 'Der Benutzer ({username}) wurde noch keinem Gremium zugewiesen.',
    please_contact_person_responsible: 'Bitte wenden Sie sich an den Fachverantwortlichen',
    back_to_start_page: 'Zurück zur Startseite',
    Business_type: 'Geschäftsart',
    Business_case_title: 'Geschäftstitel',
    Business_number: 'Geschäftsnummer',
    Wording: 'Wortlaut',
    mark_urgent: 'Als dringlich einreichen',
    mark_signature_sheet: 'Unterschriftenbogen anfordern',
    Select_file: 'Datei auswählen',
    no_agenda_items_existing: 'Keine Traktanden vorhanden',
    Back_to_meeting: 'Zurück zur Sitzung',
    Back_to_committee: 'Zurück zum Gremium',
    No_entries_existing: 'Keine Einträge verfügbar',
    Page: 'Seite',
    import_form_description: 'Um eine Sitzung hinzuzufügen können Sie das {0} einer Sitzung verwenden.',
    form_to_import: 'Formular zum Importieren',
    Import_meeting: 'Sitzung importieren',
    zip_file_label: 'ZIP-Datei mit GEVER Sitzung',
    meeting_upload_error_msg: 'Beim Importieren der Sitzung ist ein Fehler aufgetreten.',
    Back_to_agenda_item: 'Zurück zum Traktandum',
    agenda_item_proposal_document: 'Antragsdokument',
    Title: 'Titel',
    only_zip_error_msg: 'Es sind nur Dateien mit der Dateiendung .{extension} erlaubt',
    Show_versions: 'Versionen anzeigen',
    meetingDocuments: 'Sitzungsdokumente',
    committeeDocuments: 'Grundlagendokumente',
    notification_sent: 'Benachrichtigung versendet',
    other_meeting_documents: 'Sonstige Sitzungsdokumente',
    unread: 'Ungelesen',
    already_commented: 'Bereits kommentiert',
    no_documents_existing: 'Es sind keine Dokumente vorhanden.',
    Help: 'Hilfe',
    no_search_results: 'Keine Suchergebnisse',
    error_while_searching: 'Bei der Suche ist ein Fehler aufgetreten',
    meeting_imported: 'Sitzung wurde importiert',
    suggestion_posted: 'Antrag wurde eingereicht',
    proposal_posted: 'Vorstoss wurde eingereicht',
    no_data: 'Es sind keine Daten vorhanden',
    to_the_agenda_item_proposal: 'Zum Antragsdokument',
    reveal_text: 'Mehr anzeigen',
    hide_text: 'Weniger anzeigen',
    update_description: 'speichern',
    missing_meeting_description: 'Noch keine Beschreibung vorhanden.',
    create_new_meeting_description: 'Eine neue Beschreibung erfassen',
    meeting_description_placeholder: 'Beschreibung hier hinzufügen',
    meeting_description_success_message: 'Die Beschreibung wurde aktualisiert',
    meeting_description_error_message: 'Beim Aktualisieren der Beschreibung ist ein Fehler aufgetreten',
    edit_description: 'Beschreibung bearbeiten',
    share_error_message: 'Beim Freigeben der Notiz ist ein Fehler aufgetreten',
    Fragment_label_documents_available: 'Unterlagen verfügbar',
    Fragment_documents_available:
      'Die Unterlagen zur Sitzung des Gremiums {committee} vom {date} sind ab sofort verfügbar.',
    Fragment_label_meeting_updated: 'Sitzung aktualisiert',
    Fragment_meeting_updated:
      'Die Unterlagen zur Sitzung des Gremiums {committee} vom {date} wurden aktualisiert. Bitte beachten Sie die Änderungen.',
    Fragment_label_meeting_postponed: 'Sitzung verschoben',
    Fragment_meeting_postponed:
      'Bitte beachten Sie den neuen Sitzungsbeginn. Die Sitzung findet nun am {date} statt im Sitzungszimmer...',
    share_tooltip_text: 'Freigeben',
    edit_share_tooltip_text: 'Freigabe bearbeiten',
    share_tooltip_legacy: 'Diese Notizen können nicht mehr bearbeitet werden. Bitte verwenden Sie eine andere Ebene.',
    share_modal_title: 'Notizen für „{layer}“ freigeben',
    delete_layer_success_message: 'Die Notiz-Ebene wurde gelöscht',
    delete_layer_error_message: 'Beim Löschen der Notiz-Ebene ist ein Fehler aufgetreten',
    share_search_label: 'Nach Personen oder Gruppen suchen',
    share_message_label: 'Nachricht (optional). Der Link zu diesem Dokument wird automatisch eingefügt.',
    share_success_message: 'Freigabe aktualisiert',
    share_abort: 'Abbrechen',
    share_delete: 'Löschen',
    share_apply: 'Freigeben',
    share_subtitle_name: 'Name',
    share_title: 'Berechtigungen',
    share_subtitle_message: 'Benachrichtigungen',
    share_send_message_label: 'Benachrichtigung versenden',
    share_column_type: 'Typ',
    share_column_title: 'Titel',
    Spread_documents: 'Verbreitung der Unterlagen',
    Spread_documents_description:
      'Diese Übersicht zeigt, welche Mitglieder die Unterlagen dieser Sitzung bereits gesehen haben und welche noch nicht.',
    Select_members: ' | Klicken, um dieses Mitglied auszuwählen | Klicken, um diese Mitglieder auszuwählen',
    Members_read_document:
      'Noch niemand hat die Unterlagen gesehen | Ein Mitglied hat die Unterlagen gesehen | {count} Mitglieder haben die Unterlagen gesehen',
    Members_unread_document:
      'Alle Mitglieder haben die Unterlagen gesehen | Ein Mitglied hat die Unterlagen noch nicht gesehen | {count} Mitglieder haben die Unterlagen noch nicht gesehen',
    UserTable_header_name: 'Name',
    UserTable_header_email: 'E-Mail',
    UserTable_header_spread_documents: 'Unterlagen gesehen',
    share_agenda_item: 'Annotationen freigeben',
    share_agenda_item_modal_title: 'Notizen für „{agendaItem}“ freigeben',
    share_agenda_item_modal_description:
      'Es wird automatisch für jedes Dokument eine Notizebene erstellt und mit den unten ausgewählten Personen bzw. Gruppen geteilt.',
    next_agenda_item: 'Zum nächsten Traktandum',
    page_not_found: 'Seite nicht gefunden...',
    required_input_missing: 'erforderliche Angabe',
    my_personal_notes: 'Meine persönlichen Notizen',
    active: 'aktiv',
    inactive: 'inaktiv',
    committees_with_no_type: 'Gremien ohne Typ',
    document_delete_confirmation: 'Wollen Sie das Dokument wirklich löschen?',
    personal_documents: 'Persönliche Dokumente',
    personal_document: 'Persönliches Dokument',
    add_personal_document: 'Persönliches Dokument hinzufügen',
    error_on_document_upload: 'Beim Hochladen des dokuments ist ein Fehler aufgetreten.',
    membership_edit_title: 'Mitgliedschaften verwalten',
    membership_help_text:
      'Zugehörige können Sitzungsunterlagen und Sitzungen einsehen; Zuständige können Sitzungen hochladen und bearbeiten sowie die Mitgliedschaften verwalten.',
    membership_delete_confirmation: 'Wollen Sie diese Mitgliedschaft wirklich entfernen?',
    member: 'Zugehörig',
    responsible: 'Zuständig',
    roles: 'Rollen',
    begin_date: 'Von',
    end_date: 'Bis',
    searchTitle: 'Suche',
    allResults: 'Alle',
    committee: 'Gruppen',
    help_dialog_do_you_have_questions: 'Haben Sie noch Fragen?',
    help_dialog_further_information_help_center:
      'Weiterführende Informationen und die Benutzerdokumentation finden Sie in unserem Help Center',
    help_dialog_close: 'Schliessen',
    help_dialog_contact: 'Kontakt',
    help_dialog_help_center: 'Help Center',
    help_dialog_support: 'Support',
    help_dialog_title: 'Hilfethemen',
    backFromSearchTitle: 'Zurück',
  },
  fr: {
    shared_with_me: 'Documents partagés avec moi',
    appName: 'App pour les séances',
    bookmark: 'Noter',
    note: '1 note | {count} notes',
    forthcoming_meetings: 'Séances actuelles',
    current_meeting: 'Séance actuelle',
    next_meeting: 'Prochaine séance',
    currently_no_forthcoming_meetings: "Aucune séance n'est planifiée pour le moment",
    members: '1 Membre | {count} Membres',
    Search: 'Recherche',
    basic_document: 'Document de base',
    basic_documents: 'Documents de base',
    add_basic_document: 'Ajouter document de base',
    error_on_basic_document_upload: 'Une erreur est survenue lors du transfert du document de base.',
    add_document: 'Ajouter document',
    meetings: 'Meetings',
    meetingCalendar: 'Calendrier des séances',
    one_meeting: 'une séance',
    multiple_meetings: 'multiples séances',
    notes: 'Notes',
    post_proposal: 'Déposer une intervention',
    post_suggestion: 'Déposer une proposition',
    member_can_post_proposals: 'En tant que membre de cette commission, vous pouvez déposer une intervention.',
    member_can_post_suggestions: 'En tant que membre de cette commission, vous pouvez déposer une proposition.',
    meeting_from: 'Séance du {date}',
    protocol: 'Le protocole',
    protocols: 'Protocoles',
    show_protocol: 'Afficher le protocole',
    Protocol_of: 'Protocole du {date}',
    download_meeting: 'Télécharger la séance',
    agenda_items: 'Points abordés',
    no_notes_existing: "Aucune notice n'a été saisie",
    agenda_item_show_proposal: 'Afficher la requête',
    note_placeholder: 'Note ...',
    Add: 'Ajouter',
    Cancel: 'Annuler',
    Scaling_down: 'Minimiser',
    Page_width: 'Largeur de page',
    Full_page: 'Page entière',
    Scaling_up: 'Agrandir',
    mark: 'Marquer',
    Add_note: 'Ajouter note',
    Show_note: 'Afficher notices',
    Logout: 'Déconnexion',
    for_committee: 'pour commission',
    only_for_me: 'uniquement pour moi',
    visibility: 'Visibilité',
    add_note_here: 'Saisir la note ici',
    create_shortcut: "Windows: Ctrl + Entrée  |  OS X: CMD + Entrée pour l'ajout direct.",
    save_shortcut: "Windows: Ctrl + Entrée  | OS X: CMD + Entrée pour l'enregistrement direct.",
    Save: 'Sauvegarder',
    Note_in_context: 'Note dans son contexte',
    Committee: 'Commission',
    hint_visible_for_committee: 'Cette note es visible pour tous les membres de la commission.',
    Only_me: 'Seulement moi',
    hint_visible_only_for_me: 'Cette note personnelle est uniquement accessible par moi.',
    this_note_is_outdated: 'Cette note est obsolète.',
    Edit: 'Modifier',
    Remove: 'Effacer',
    Removed_notes: 'Notes Supprimées',
    hint_outdated_note: 'Cette notice a été créé pour un point qui, entretemps, ne fait plus partie de cette séance ',
    hint_copy_outdated_note:
      "Si cette note est toujours requise, veuillez en copier le texte et l'ajouter à une nouvelle notice",
    close: 'fermer',
    Close: 'Fermer',
    no_attachments_exist: 'Pas de pièces jointes',
    Attachments: 'Pièces jointes',
    Documents: 'Documents',
    agenda_item_proposal: 'Requête',
    agenda_item_proposals: 'Requêtes',
    Note: 'Note',
    Send_notification: 'Envoyer une notification',
    notification_description:
      "Les participants sélectionnés peuvent être notifiés de la mise en ligne, de changements ou d'autres évènements concernant la séance ici. Ces derniers recevront un email contenant le lien vers cette séance. Le champ de texte permet également d'inclure un message personnalisé.",
    add_notification_text_here: 'Insérer un message ici',
    Send: 'Envoyer',
    Add_date: 'Insérer date',
    Add_title: 'Insérer titre',
    Automatic_Message: 'Message automatique',
    No_preview_available: "Pas d'aperçu disponible pour ce document",
    Select: 'Sélectionner',
    Create: 'Créer',
    Update: 'Mettre à jour',
    last_update_on: 'Dernière mise à jour le {date} heures',
    updated_last_on: 'Mis à jour le {date} heures',
    no_committees_existing: 'Pas de commissions existantes',
    no_basic_documents_existing: 'Pas de documents de base existants',
    no_users_existing: "Pas d'utilisateur existants",
    user_not_assigned_to_a_committee: "L'utilisateur ({username}) n'a pas encore été assigné à une commission.",
    please_contact_person_responsible: 'Veuillez contacter le responsable technique',
    back_to_start_page: "Retour à la page d'accueil",
    Business_type: "Type d'affaire",
    Business_case_title: "Titre d'affaire",
    Business_number: "Numéro d'affaire",
    Wording: 'Description',
    mark_urgent: 'Urgent',
    Select_file: 'Choisir un fichier',
    no_agenda_items_existing: 'Aucun point à aborder',
    Back_to_meeting: 'Retour à la séance',
    Back_to_committee: 'Retour à la commission',
    No_entries_existing: 'Aucune entrée existante',
    Page: 'Page',
    import_form_description: 'Pour ajouter une séance, vous pouvez utiliser le {0}.',
    form_to_import: "Formulaire d'importation",
    Import_meeting: 'Importer séance',
    zip_file_label: 'Fichier ZIP contenant une séance GEVER',
    meeting_upload_error_msg: "Une erreur est survenue lors de l'import de la séance",
    Back_to_agenda_item: "Retour à l'ordre du jour",
    agenda_item_proposal_document: 'Document de requête',
    Title: 'Titre',
    only_zip_error_msg: "Seuls les fichiers avec l'extension .{extension} sont autorisés",
    Show_versions: 'Afficher les versions',
    meetingDocuments: 'Documents de la séance',
    committeeDocuments: 'Documents de base',
    notification_sent: 'Notifications envoyées',
    other_meeting_documents: 'Autres documents de séance',
    unread: 'Non lu',
    already_commented: 'Commentaire existant',
    no_documents_existing: "Il n'y a pas de documents disponibles.",
    Help: 'Aide',
    no_search_results: 'Aucun résultat de recherche',
    error_while_searching: 'Une erreur est survenue lors de la recherche',
    meeting_imported: 'La séance a été importée',
    suggestion_posted: 'La proposition a été soumise',
    proposal_posted: "L'intervention a été déposée",
    no_data: 'Aucune donnée disponible',
    to_the_agenda_item_proposal: 'Vers le document de requête',
    reveal_text: 'Afficher plus',
    hide_text: 'Afficher moins',
    update_description: 'Sauvegarder',
    missing_meeting_description: 'Aucune description disponible pour le moment.',
    create_new_meeting_description: 'Ajouter une description',
    meeting_description_placeholder: 'Ajouter la description ici',
    meeting_description_success_message: 'La description a été actualisée',
    meeting_description_error_message: "Une erreur est survenue lors de l'actualisation de la description",
    edit_description: 'Modifier la description',
    share_error_message: "Une erreur est survenue lors du partage de l'annotation",
    Fragment_label_documents_available: 'Documents de séance disponibles',
    Fragment_documents_available:
      'Les documents de la séance du groupe {committee} du {date} sont maintenant disponibles.',
    Fragment_label_meeting_updated: 'Séance mise à jour',
    Fragment_meeting_updated:
      'Les documents de la séance du groupe {committee} du {date} ont été mis à jour. Veuillez prendre note des modifications.',
    Fragment_label_meeting_postponed: 'Séance reportée',
    Fragment_meeting_postponed:
      'veuillez prendre note de la nouvelle date/heure de début de la séances. La séance a lieu le {date} dans la salle de réunion...',
    share_tooltip_text: 'partager',
    edit_share_tooltip_text: 'Modifier les paramètres de partage',
    share_tooltip_legacy: 'Ces annotations ne peut plus être modifié. Veuillez utiliser une autre couche.',
    share_modal_title: 'Partager les annotations pour „{layer}"',
    delete_layer_success_message: "La couche d'annotation a été supprimée",
    delete_layer_error_message: "Une erreur est survenue lors de la suppression de la couche d'annotation",
    share_search_label: 'Chercher des personnes ou des groupes',
    share_message_label: 'Message(facultatif). Le lien vers ce document sera inséré automatiquement.',
    share_success_message: 'Partage mis à jour',
    share_abort: 'Annuler',
    share_delete: 'Effacer',
    share_apply: 'Partager',
    share_subtitle_name: 'Nom',
    share_title: 'Permissions',
    share_subtitle_message: 'Notifications',
    share_send_message_label: 'Envoyer des notifications',
    share_column_type: 'Type',
    share_column_title: 'Titre',
    Spread_documents: 'Diffusion des documents',
    Spread_documents_description:
      'Cet aperçu vous montre quels membres ont déjà consulté les documents de cette séance - ou pas.',
    Select_members: ' | Cliquez ici pour sélectionner ce membre | Cliquez ici pour sélectionner ces membres',
    Members_read_document:
      "Personne n'a encore consulté les documents | Un membre a consulté les documents | {count} membres ont consulté les documents",
    Members_unread_document:
      "Tous les membres ont consulté les documents | Un membre n'a pas encore consulté les documents | {count} membres n'ont pas encore consulté les documents",
    UserTable_header_name: 'Nom',
    UserTable_header_email: 'E-mail',
    UserTable_header_spread_documents: 'Documents consultés',
    share_agenda_item: 'Partager les annotations',
    share_agenda_item_modal_title: 'Notes publiées pour „{agendaItem}"',
    share_agenda_item_modal_description:
      'Pour chaque document une couche de notes est automatiquement créée et partagée avec les personnes ou groupes sélectionnés ci-dessous.',
    next_agenda_item: "Prochain point de l'ordre du jour",
    page_not_found: 'Page non trouvée...',
    required_input_missing: 'Informations requises',
    my_personal_notes: 'Mes notes personnelles',
    active: 'actif',
    inactive: 'inactif',
    committees_with_no_type: 'Commissions sans type',
    document_delete_confirmation: 'Êtes-vous sûr de vouloir supprimer ce document?',
    personal_documents: 'Documents personnels',
    personal_document: 'Document personnel',
    add_personal_document: 'Ajouter document personnel',
    error_on_document_upload: 'Une erreur est survenue lors du transfert du document.',
    membership_edit_title: 'Gérer les affiliations',
    membership_help_text:
      'Les membres peuvent consulter les réunions et leurs documents. Les responsables peuvent télécharger et modifier les réunions et gérer les adhésions.',
    membership_delete_confirmation: 'Êtes-vous sûr de vouloir supprimer cette adhésion ?',
    member: 'Membre',
    responsible: 'Responsable',
    roles: 'Rôles',
    begin_date: 'De',
    end_date: "Jusqu'à",
    searchTitle: 'Rechercher',
    help_dialog_do_you_have_questions: "Avez-vous d'autres questions?",
    help_dialog_further_information_help_center:
      'Vous trouverez de plus amples informations ainsi que le guide d’utilisation dans notre help center',
    help_dialog_close: 'Fermer',
    help_dialog_contact: 'Contact',
    help_dialog_helpCenter: 'Help Center',
    help_dialog_support: 'Support',
    help_dialog_title: 'Assistance',
    backFromSearchTitle: 'Retour',
  },
  en: {
    shared_with_me: 'Documents shared with me',
    appName: 'Meeting-App',
    bookmark: 'Remember',
    note: '1 note | {count} notes',
    forthcoming_meetings: 'Upcoming Meetings',
    current_meeting: 'Current Meeting',
    next_meeting: 'Next Meeting',
    currently_no_forthcoming_meetings: 'No sessions are planned at the moment.',
    members: '1 member | {count} members',
    Search: 'Search',
    basic_document: 'Fundamental document',
    basic_documents: 'Fundamental Documents',
    add_basic_document: 'Add fundamental document',
    error_on_basic_document_upload: 'An error occurred while uploading the fundamental document.',
    add_document: 'Add document',
    meetings: 'Meetings',
    meetingCalendar: 'Meetings Calendar',
    one_meeting: 'one session',
    multiple_meetings: 'several sessions',
    notes: 'Notes',
    post_proposal: 'Submit a proposal',
    post_suggestion: 'Submit a suggestion',
    member_can_post_proposals: 'As a member of this committee, you can submit a proposal.',
    member_can_post_suggestions: 'As a member of this committee, you can submit a suggestion.',
    meeting_from: 'Meeting from {date}',
    protocol: 'Minutes',
    protocols: 'Minutes',
    show_protocol: 'Show minutes',
    Protocol_of: 'Minutes from {date}',
    download_meeting: 'Download meeting',
    agenda_items: 'Agenda Items',
    no_notes_existing: "Aucune note n'a été saisie",
    agenda_item_show_proposal: 'Show proposal',
    note_placeholder: 'Note ...',
    Add: 'Add',
    Cancel: 'Cancel',
    Scaling_down: 'Zoom out',
    Page_width: 'Fit width',
    Full_page: 'Fit page',
    Scaling_up: 'Zoom in',
    mark: 'Mark',
    Add_note: 'Add note',
    Show_note: 'Show notes',
    Logout: 'Log out',
    for_committee: 'for committee',
    only_for_me: 'only for me',
    visibility: 'Visibility',
    add_note_here: 'Add note here',
    create_shortcut: 'Windows: Ctrl + Enter  |  OS X: CMD + Enter to create immediately.',
    save_shortcut: 'Windows: Ctrl + Enter  | OS X: CMD + Enter to save immediately.',
    Save: 'Save',
    Note_in_context: 'Note in context',
    Committee: 'Committee',
    hint_visible_for_committee: 'This note can be viewed by all participants in the committee.',
    Only_me: 'Only me',
    hint_visible_only_for_me: 'This personal note is only visible to me.',
    this_note_is_outdated: 'This note is outdated.',
    Edit: 'Edit',
    Remove: 'Delete',
    Removed_notes: 'Removed notes',
    hint_outdated_note: 'This note was written for an agenda item that is no longer part of a meeting.',
    hint_copy_outdated_note: 'If this note is still needed, you can copy the text and keep it in a new note.',
    close: 'close',
    Close: 'Close',
    no_attachments_exist: 'No attachments exist',
    Attachments: 'Attachments',
    Documents: 'Documents',
    agenda_item_proposal: 'Proposal',
    agenda_item_proposals: 'Proposals',
    Note: 'Notes',
    Send_notification: 'Send notification',
    notification_description:
      'Here you can inform selected participants about the meeting, a change or other events. The selected persons will receive an e-mail with the link to the session. In addition, you can enter your own message in the text field, which will be displayed in the e-mail.',
    add_notification_text_here: 'Insert notification text here',
    Send: 'Send',
    Add_date: 'Insert date',
    Add_title: 'Insert document title',
    Automatic_Message: 'Automatic message',
    No_preview_available: 'There is no preview available for this document.',
    Select: 'Select',
    Create: 'Create',
    Update: 'Update',
    last_update_on: 'Last updated at {date}',
    updated_last_on: 'Last updated at {date}',
    no_committees_existing: 'No committees available',
    no_basic_documents_existing: 'No fundamental documents available',
    no_users_existing: 'No users available',
    user_not_assigned_to_a_committee: 'The user ({username}) has not yet been assigned to a committee.',
    please_contact_person_responsible: 'Please contact the person responsible for the subject',
    back_to_start_page: 'Return to the homepage',
    Business_type: 'Business type',
    Business_case_title: 'Business title',
    Business_number: 'Business number',
    Wording: 'Wording',
    mark_urgent: 'Urgent',
    Select_file: 'Select file',
    no_agenda_items_existing: 'No agenda items available',
    Back_to_meeting: 'Return to the meeting',
    Back_to_committee: 'Return to the committee',
    No_entries_existing: 'No entries available',
    Page: 'Page',
    import_form_description: 'To add a session you can use the {0} of a session.',
    form_to_import: 'Import form',
    Import_meeting: 'Import meeting',
    zip_file_label: 'ZIP file with GEVER meeting',
    meeting_upload_error_msg: 'An error occurred while importing the meeting.',
    Back_to_agenda_item: 'Return to the agenda item',
    agenda_item_proposal_document: 'Proposal document',
    Title: 'Title',
    only_zip_error_msg: 'Only files with the file extension .{extension} are allowed',
    Show_versions: 'Show versions',
    meetingDocuments: 'Meeting documents',
    committeeDocuments: 'Fundamental documents',
    notification_sent: 'Notification sent',
    other_meeting_documents: 'Other meeting documents',
    unread: 'Unread',
    already_commented: 'Already commented',
    no_documents_existing: 'There are no documents available.',
    Help: 'Help',
    no_search_results: 'No search results',
    error_while_searching: 'An error occurred during the search',
    meeting_imported: 'Meeting was imported',
    suggestion_posted: 'Suggestion was submitted',
    proposal_posted: 'Proposal was submitted',
    no_data: 'There is no data available',
    to_the_agenda_item_proposal: 'To the proposal document',
    reveal_text: 'Show more',
    hide_text: 'Show less',
    update_description: 'Save',
    missing_meeting_description: 'No description available yet.',
    create_new_meeting_description: 'Add a new description',
    meeting_description_placeholder: 'Add a description here',
    meeting_description_success_message: 'The description has been updated',
    meeting_description_error_message: 'An error occurred when updating the description',
    edit_description: 'Edit description',
    share_error_message: 'An error occurred while sharing the note',
    Fragment_label_documents_available: 'Documents available',
    Fragment_documents_available: 'The documents for the {committee} meeting of {date} are now available.',
    Fragment_label_meeting_updated: 'Meeting updated',
    Fragment_meeting_updated:
      'The documents for the {committee} meeting of {date} have been updated. Please note the changes.',
    Fragment_label_meeting_postponed: 'Meeting postponed',
    Fragment_meeting_postponed:
      'Please note the new meeting start date. The meeting will now take place on {date} in the meeting room....',
    share_tooltip_text: 'Share',
    edit_share_tooltip_text: 'Restrict sharing',
    share_tooltip_legacy: 'These notes can no longer be edited. Please use another layer.',
    share_modal_title: 'Share annotations for "{layer}"',
    delete_layer_success_message: 'The annotations layer was deleted',
    delete_layer_error_message: 'An error occurred when deleting the note layer',
    share_search_label: 'Search for persons or groups',
    share_message_label: 'Message (optional). The link to this document will be inserted automatically.',
    share_success_message: 'Sharing options updated',
    share_abort: 'Cancel',
    share_delete: 'Delete',
    share_apply: 'Share',
    share_subtitle_name: 'Name',
    share_title: 'Permissions',
    share_subtitle_message: 'Notifications',
    share_send_message_label: 'Send notification',
    share_column_type: 'Type',
    share_column_title: 'Title',
    Spread_documents: 'Document distribution',
    Spread_documents_description:
      'This overview shows which members have already viewed the documents of this session and which have not yet.',
    // If no members in this category we don't show this message
    Select_members: ' | Click here to select this member | Click here to select these members',
    Members_read_document:
      'No one has viewed the documents yet | One member has viewed the documents | {count} members have viewed the documents',
    Members_unread_document:
      'All members have viewed the documents | One member has not yet viewed the documents | {count} members have not yet viewed the documents',
    UserTable_header_name: 'Name',
    UserTable_header_email: 'E-mail',
    UserTable_header_spread_documents: 'Has viewed documents',
    share_agenda_item: 'Share annotations',
    share_agenda_item_modal_title: 'Share notes for "{agendaItem}".',
    share_agenda_item_modal_description:
      'A note layer is automatically created for each document and shared with the people or groups selected below.',
    next_agenda_item: 'Next Agenda Item',
    page_not_found: 'Page not found...',
    required_input_missing: 'Required input missing',
    my_personal_notes: 'My personal notes',
    active: 'active',
    inactive: 'inactive',
    committees_with_no_type: 'Committees with no type',
    document_delete_confirmation: 'Are you sure you want to delete this document?',
    personal_documents: 'Personal Documents',
    personal_document: 'Personal document',
    add_personal_document: 'Add personal document',
    error_on_document_upload: 'An error occurred while uploading the personal document.',
    membership_edit_title: 'Manage memberships',
    membership_help_text:
      'Members may view meetings and their documents. Responsibles can upload and edit meetings and manage memberships.',
    membership_delete_confirmation: 'Are you sure you want to delete this membership?',
    member: 'Member',
    responsible: 'Responsible',
    roles: 'Roles',
    begin_date: 'From',
    end_date: 'Until',
    searchTitle: 'Search',
    help_dialog_do_you_have_questions: 'Do you have any questions?',
    help_dialog_further_information_help_center:
      'Further information and the user documentation can be found in our Help Center',
    help_dialog_close: 'Close',
    help_dialog_contact: 'Contact',
    help_dialog_helpCenter: 'Help Center',
    help_dialog_support: 'Support',
    help_dialog_title: 'Help',
    backFromSearchTitle: 'Back',
  },
}

export default messages
