<template>
  <NotificationDialog :fragment-boxes="fragmentBoxes" :url="notificationURL" :principals="meeting.principals">
    <template v-slot:activator="activatorProps">
      <slot name="activator" v-bind="activatorProps" />
    </template>
  </NotificationDialog>
</template>

<script>
import { createLink } from '@/api/helpers'
import Committee from '@/store/models/committee'
import Meeting from '@/store/models/meeting'
import User from '@/store/models/user'

const notificationURLTemplate = '/{documentPath}/{documentId}/versions/{versionId}/notification/'

export default {
  name: 'DocumentNotificationDialog',
  props: {
    document: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      meeting: { principals: [] },
    }
  },
  computed: {
    fragmentBoxes() {
      return [
        {
          label: this.$t('Add_title'),
          content: this.document.title,
        },
      ]
    },
    notificationURL() {
      return createLink(notificationURLTemplate, {
        documentPath: this.document.path,
        documentId: this.document.id,
        versionId: this.document.version_id,
      })
    },
  },
  async created() {
    // (Re-)Fetch the users of the current committee allowed to receive notifications about this
    // document. This is necessary when accessing the document URL directly as in this case, the
    // users are not loaded yet.
    this.meeting = await Meeting.findOrFetch(this.document.meetingId)
    const committee = await Committee.findOrFetch(this.meeting.committee_id)
    await User.fetchAllByModel(committee)
  },
}
</script>
