import Versionable from './versionable'
import Annotationable from './mixins/annotationable'

class Attachment extends Versionable {
  static entity = 'attachment'

  static apiPath = 'attachments'

  static primaryKey = 'version_id'

  static fields() {
    return {
      id: this.attr(null),
      title: this.string(''),
      file: this.string(''),
      created: this.attr('', (v) => new Date(v)),
      updated: this.attr('', (v) => new Date(v)),
      agenda_item_id: this.number(0),
      agenda_item: this.belongsTo('agendaitem', 'agenda_item_id', 'id'),
      version_id: this.attr(null),
      annotations: this.morphMany('note', 'object_id', 'content_type', 'id'),
      file_extension: this.attr(null),
      has_annotations: this.boolean(false),
      last_seen: this.attr(null),
      annotationLayers: this.morphMany('annotation_layer', 'object_id', 'content_type'),
      selected_layer: this.attr(null),
      sort_order: this.attr(null),
    }
  }

  get breadcrumbText() {
    return this.title
  }

  get meetingId() {
    return this.agenda_item.meeting_id
  }
}

Object.assign(Attachment.prototype, Annotationable)

export default Attachment
