<template>
  <NotificationDialog
    :fragments="fragments"
    :fragment-context="fragmentContext"
    :fragment-boxes="fragmentBoxes"
    :url="notificationURL"
    :principals="meeting.principals"
  >
    <template v-slot:activator="activatorProps">
      <slot name="activator" v-bind="activatorProps" />
    </template>
    <p slot="description" class="caption">
      {{ $t('notification_description') }}
    </p>
  </NotificationDialog>
</template>

<script>
import { createLink } from '@/api/helpers'
import { toLocaleDateTimeString } from '@/filters'
import fragments from '@/text-fragments'

const notificationURLTemplate = '/committees/{committee}/meetings/{meeting}/notification/'

export default {
  name: 'MeetingNotificationDialog',
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fragments: fragments.meeting,
    }
  },
  computed: {
    fragmentContext() {
      return {
        committee: this.meeting.committee.title,
        date: toLocaleDateTimeString(this.meeting.start),
      }
    },
    fragmentBoxes() {
      return [
        {
          label: this.$t('Add_date'),
          content: toLocaleDateTimeString(this.meeting.start),
        },
      ]
    },
    notificationURL() {
      return createLink(notificationURLTemplate, {
        committee: this.meeting.committee_id,
        meeting: this.meeting.id,
      })
    },
  },
  methods: {
    toLocaleDateTimeString,
  },
}
</script>
