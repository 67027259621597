import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import VuexPersistence from 'vuex-persist'
import VuexORMSearch from '@vuex-orm/plugin-search'
import AgendaItem from '@/store/models/agendaitem'
import Attachment from '@/store/models/attachment'
import Committee from '@/store/models/committee'
import Meeting from '@/store/models/meeting'
import Note from '@/store/models/note'
import note from '@/store/modules/note'
import PersonalDocument from '@/store/models/personaldocument'
import Proposal from '@/store/models/proposal'
import Protocol from '@/store/models/protocol'
import User from '@/store/models/user'
import Profile from '@/store/models/profile'
import Config from '@/store/models/config'
import Document from '@/store/models/document'
import MeetingDocument from '@/store/models/meetingdocument'
import Membership from '@/store/models/memberships'
import AnnotationLayer from '@/store/models/annotation_layer'
import Annotation from '@/store/models/annotation'

import ApplicationModule from '@/store/modules/application'
import PDFViewerModule from '@/store/modules/pdfviewer'
import RisProposal from '@/store/modules/risproposal'
import RisSuggestion from '@/store/modules/rissuggestion'
import Notification from '@/store/modules/notification'
import AppSwitcher from '@/store/modules/appswitcher'

Vue.use(Vuex)

VuexORM.use(VuexORMSearch)

export const database = new VuexORM.Database()

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['risproposal', 'rissuggestion', 'pdfviewer'],
  reducer: (state) => ({
    // This only persists the risproposal module
    risproposal: state.risproposal,
    rissuggestion: state.rissuggestion,
    pdfviewer: state.pdfviewer,
  }),
})

database.register(Committee, {})
database.register(Meeting, {})
database.register(AgendaItem, {})
database.register(Attachment, {})
database.register(Note, note)
database.register(PersonalDocument, {})
database.register(Proposal, {})
database.register(Protocol, {})
database.register(User, {})
database.register(Profile, {})
database.register(Config, {})
database.register(Document, {})
database.register(MeetingDocument, {})
database.register(Membership, {})
database.register(AnnotationLayer, {})
database.register(Annotation, {})

export default new Vuex.Store({
  plugins: [VuexORM.install(database), vuexLocal.plugin],
  modules: {
    application: ApplicationModule,
    pdfviewer: PDFViewerModule,
    risproposal: RisProposal,
    rissuggestion: RisSuggestion,
    notification: Notification,
    appswitcher: AppSwitcher,
  },
})
