import Vue from 'vue'
import isUndefined from 'lodash/isUndefined'

export function loadFeatures() {
  if (isUndefined(process.env.VUE_APP_FEATURES)) {
    return {}
  }
  return JSON.parse(process.env.VUE_APP_FEATURES)
}

Vue.mixin({
  data() {
    return {
      features: loadFeatures(),
    }
  },
})
