export function toLocaleDateString(date = new Date()) {
  return new Date(date).toLocaleDateString('de-CH', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
}

export function toLocaleDateTimeString(date = new Date()) {
  return new Date(date).toLocaleDateString('de-CH', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  })
}

/**
 * Function to get the year and month of a date in a string
 * Example:
 *   toISOYearMonthString('2000-02-01')
 *   -> "2000-02"
 */
export function toISOYearMonthString(date = new Date()) {
  return new Date(date).toISOString().substr(0, 7)
}
