var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{ref:"main",staticClass:"search-input",style:({ 'max-width': '500px', 'min-width': _vm.$vuetify.breakpoint.xl ? '500px' : '150px' }),attrs:{"autofocus":"","text":"","filled":"","hide-details":"","color":"white","prepend-inner-icon":"mdi-magnify","placeholder":((_vm.$t('Search')) + "…"),"items":_vm.items,"search-input":_vm.query,"no-data-text":_vm.$t('no_search_results'),"no-filter":"","return-object":"","loading":_vm.loading,"clearable":"","value":_vm.selectedResult,"hide-no-data":_vm.loading || !_vm.query,"data-testid":"livesearch","dense":""},on:{"update:searchInput":function($event){_vm.query=$event},"update:search-input":function($event){_vm.query=$event},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.openSearchView.apply(null, arguments)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var ref_item = ref.item;
var text = ref_item.text;
var type = ref_item.type;
var highlightings = ref_item.highlightings;
var breadcrumbs = ref_item.breadcrumbs;
var value = ref_item.value;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({on:{"click":function($event){return _vm.openSearchResult(type, value)}}},'v-list-item',attrs,false),on),[_c('v-list-item-avatar',{staticClass:"mt-3",attrs:{"size":"24"}},[_c('v-icon',{staticClass:"search-result-icon blue-grey--text text--lighten-1"},[_vm._v(_vm._s(_vm.getIconForType(type)))])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"blue-grey--text text--lighten-1 caption"},[_vm._v(" "+_vm._s(breadcrumbs)+" ")]),_c('v-list-item-title',{staticClass:"blue-grey--text text--darken-3 body-2",domProps:{"innerHTML":_vm._s((highlightings.title && highlightings.title[0]) || text)}}),(_vm.showSearchableText(type))?_vm._l((highlightings.searchable_text),function(highlighting,index){return _c('v-list-item-subtitle',{key:("highlighting-" + index),staticClass:"blue-grey--text text--darken-3 caption search-result-searchable-text",domProps:{"innerHTML":_vm._s(highlighting)}})}):_vm._e()],2)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }