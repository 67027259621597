<template>
  <LinkedListItem :clickable="!item.is_paragraph" :item="item" data-testid="agenda-item-list-item">
    <v-list-item-action @click.prevent>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <!-- Tooltip won't work on v-checkbox directly. To fix it the span is used -->
          <span v-bind="attrs" v-on="on">
            <v-checkbox
              v-model="item.bookmark"
              off-icon="mdi-bookmark-outline"
              on-icon="mdi-bookmark"
              :data-testid="`bookmark_${item.id}`"
              color="primary"
              @change="updateBookmark"
            ></v-checkbox>
          </span>
        </template>
        <span>{{ $t('bookmark') }}</span>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title v-if="item.is_paragraph" :key="item.listKey" class="font-weight-bold text-wrap">
        {{ item.titleWithNumber }}
      </v-list-item-title>
      <v-list-item-title v-else class="text-wrap">
        {{ item.titleWithNumber }}
      </v-list-item-title>
      <v-list-item-subtitle v-if="item.proposal_id">
        <router-link :to="item.proposal.url">
          {{ $t('to_the_agenda_item_proposal') }}
        </router-link>
      </v-list-item-subtitle>
    </v-list-item-content>
    <ListActionIndicator :item="item" />
  </LinkedListItem>
</template>

<script>
import flatten from 'lodash/flatten'
import mixin from '@/components/listitems/mixin'
import AgendaItem from '@/store/models/agendaitem'

export default {
  name: 'AgendaitemListItem',
  mixins: [mixin],
  computed: {
    notesLength() {
      return [
        ...(this.item.notes || []), // Agendaitem notes
        ...flatten(this.item.attachments.map((a) => a.annotations || [])), // Attachment notes
        ...(this.item.proposal ? this.item.proposal.annotations || [] : []), // Proposal notes
      ].length
    },
  },
  methods: {
    updateBookmark() {
      AgendaItem.find(this.item.id).setBookmark(this.item.bookmark)
    },
  },
}
</script>
