var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-shrink-0"},[_c('DocumentNotificationDialog',{attrs:{"document":_vm.document},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',_vm._g(_vm._b({attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","data-testid":"btn-send-notification"}},'v-btn',Object.assign({}, tooltipAttrs, attrs),false),Object.assign({}, on, tooltipOn)),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-bell")])],1)]}}],null,true)},'v-tooltip',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.$t('Send_notification')))])])]}}])}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.toggleVersionsSidebar}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-content-copy")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Show_versions')))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }