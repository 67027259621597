<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
/* Override list item styles to prevent text from being clamped. */
.v-list__tile {
  padding-top: 10px;
  padding-bottom: 10px;
}

.v-list__tile,
.v-list__tile__title {
  height: auto !important;
}

.v-list__tile__title {
  white-space: normal !important;
}
.v-data-table-header th {
  white-space: nowrap;
}
</style>
