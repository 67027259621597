<template>
  <LinkedListItem :item="item" data-testid="attachment-list-item">
    <v-list-item-avatar class="rounded-0">
      <v-icon>{{ `${item.mimeTypeIcon}` }}</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="text-wrap">
        {{ item.title }}
      </v-list-item-title>
      <v-list-item-subtitle class="text-wrap">
        {{ $t('last_update_on', { date: toLocaleDateTimeString(item.updated) }) }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <ListActionIndicator :item="item" />
  </LinkedListItem>
</template>

<script>
import mixin from '@/components/listitems/mixin'
import { toLocaleDateTimeString } from '@/filters'

export default {
  name: 'AttachmentListItem',
  mixins: [mixin],
  methods: {
    toLocaleDateTimeString,
  },
}
</script>
