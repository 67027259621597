import set from 'lodash/set'
import Config from '../models/config'

const state = {
  currentFullPath: null,
  drawer: null,
  rootUrl: null,
  versionsSidebar: null,
  version: null,
}

const mutations = {
  set(state, { path, value }) {
    set(state, path, value)
  },
}

const actions = {
  toggleDrawer(context) {
    context.state.drawer = !context.state.drawer
  },
  async setConfig(context) {
    const config = await Config.query().first()
    context.commit('set', { path: 'version', value: config.version })
    context.commit('set', { path: 'rootUrl', value: config.root_url })
  },
  setDrawer(context, { drawer }) {
    context.state.drawer = drawer
  },
  setVersionsSidebar(context, { versionsSidebar }) {
    context.state.versionsSidebar = versionsSidebar
  },
  toggleVersionsSidebar(context) {
    context.state.versionsSidebar = !context.state.versionsSidebar
  },
  setCurrentFullPath(context, fullPath) {
    context.commit('set', { path: 'currentFullPath', value: fullPath })
  },
}

const getters = {
  getDrawer(s) {
    return s.drawer
  },
  getVersionsSidebar(s) {
    return s.versionsSidebar
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
