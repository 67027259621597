import PSPDFKit from 'pspdfkit'
import get from 'lodash/get'
import set from 'lodash/set'

const serializableColorPaths = [
  'polyline.strokeColor',
  'highlighter.strokeColor',
  'text-highlighter.color',
  'ink.strokeColor',
  'arrow.strokeColor',
  'squiggle.color',
  'underline.color',
  'text.fontColor',
  'rectangle.strokeColor',
  'ink-signature.strokeColor',
  'note.color',
  'highlight.color',
  'line.strokeColor',
  'polygon.strokeColor',
  'strikeout.color',
  'ellipse.strokeColor',
]

const defaultAnnotationPresets = {
  polyline: { strokeWidth: 5, strokeColor: { r: 36, g: 131, b: 199 } },
  highlighter: {
    lineWidth: 30,
    strokeColor: { r: 252, g: 238, b: 124 },
    isDrawnNaturally: false,
    isSignature: false,
    blendMode: 'multiply',
    opacity: 1,
  },
  'text-highlighter': { rects: [], color: { r: 252, g: 238, b: 124 }, blendMode: 'multiply', opacity: 1 },
  stamp: { stampType: 'Custom', rotation: 0 },
  widget: { isBold: false, isItalic: false, rotation: 0 },
  ink: {
    lineWidth: 5,
    strokeColor: { r: 36, g: 131, b: 199 },
    isDrawnNaturally: false,
    isSignature: false,
    blendMode: 'normal',
  },
  arrow: { strokeWidth: 5, strokeColor: { r: 36, g: 131, b: 199 }, lineCaps: { end: 'openArrow' } },
  squiggle: { rects: [], color: { r: 248, g: 36, b: 0 }, blendMode: 'normal' },
  underline: { rects: [], color: { r: 0, g: 0, b: 0 }, blendMode: 'normal' },
  text: {
    fontColor: { r: 0, g: 0, b: 0 },
    font: 'Helvetica',
    rotation: 0,
    fontSize: 18,
    isBold: false,
    isItalic: false,
    horizontalAlign: 'left',
    verticalAlign: 'top',
    isFitting: false,
  },
  rectangle: { strokeWidth: 5, strokeColor: { r: 36, g: 131, b: 199 }, cloudyBorderIntensity: 0 },
  'ink-signature': {
    lineWidth: 5,
    strokeColor: { r: 36, g: 131, b: 199 },
    isDrawnNaturally: false,
    isSignature: true,
    blendMode: 'normal',
  },
  note: { text: '', icon: 'COMMENT', color: { r: 255, g: 216, b: 63 } },
  highlight: { rects: [], color: { r: 252, g: 238, b: 124 }, blendMode: 'multiply' },
  line: { strokeWidth: 5, strokeColor: { r: 36, g: 131, b: 199 } },
  polygon: { strokeWidth: 5, strokeColor: { r: 36, g: 131, b: 199 }, cloudyBorderIntensity: 0 },
  image: { rotation: 0 },
  strikeout: { rects: [], color: { r: 248, g: 36, b: 0 }, blendMode: 'normal' },
  ellipse: { strokeWidth: 5, strokeColor: { r: 36, g: 131, b: 199 }, cloudyBorderIntensity: 0 },
}

const state = {
  annotationPresets: defaultAnnotationPresets,
}

const mutations = {
  updateAnnotationPresets(state, { currentPreset, newPresetProperties }) {
    Object.assign(state.annotationPresets[currentPreset], newPresetProperties)
  },
  setAnnotationPresets(state, annotationPresets) {
    state.annotationPresets = annotationPresets
  },
}

const actions = {
  updateAnnotationPresets({ commit }, { currentPreset, newPresetProperties }) {
    commit('updateAnnotationPresets', { currentPreset, newPresetProperties })
  },
  setAnnotationPresets({ commit }, annotationPresets) {
    commit('setAnnotationPresets', annotationPresets)
  },
}

const getters = {
  getAnnotationPresets(s) {
    if (!s.annotationPresets) return null
    const annotationPresets = PSPDFKit.AnnotationPresets.fromSerializableObject(s.annotationPresets)
    // Deserialize the colors
    serializableColorPaths.forEach((path) => {
      set(annotationPresets, path, new PSPDFKit.Color(get(annotationPresets, path)))
    })
    return annotationPresets
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
