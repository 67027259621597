<template>
  <Toolbar>
    <v-toolbar-title ml-2>{{ title }}</v-toolbar-title>
  </Toolbar>
</template>

<script>
export default {
  name: 'TitledToolbar',
  props: {
    title: {
      type: String,
      default: () => '',
    },
  },
}
</script>
