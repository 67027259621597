<template>
  <BaseLayout hide-search>
    <Sidebar slot="drawer-left">
      <v-list>
        <BackListItem :parent="{ url: currentFullPath }" :title="$t('backFromSearchTitle')" />
      </v-list>
    </Sidebar>

    <v-card>
      <v-card-title>
        <h3 class="display-1">{{ $t('searchTitle') }}</h3>
      </v-card-title>
      <v-card-text>
        <SearchField :query.sync="filter.query" autofocus />
        <v-tabs v-if="hasTypes" v-model="filterType" class="mb-3" slider-color="primary" grow show-arrows>
          <SearchTab :count="totalItems" :title="$t('allResults')" href="#" />
          <SearchTab
            v-for="entry in sortedTypes"
            :key="entry.type"
            :count="entry.count"
            :href="`#${entry.type}`"
            :title="entry.title"
            nuxt
          />
        </v-tabs>
        <ServersideListing :fetch="search" :filter="filter">
          <template #item="{ item }">
            <v-card flat tile class="search-result" @click="openSearchResult(item)">
              <!-- eslint-disable vue/no-v-html -->
              <v-card-title class="py-2 subtitle-1">
                <v-icon :size="20" class="search-result-icon blue-grey--text text--lighten-1 mr-2">{{
                  getIconForType(item.type)
                }}</v-icon>
                <span v-html="(item.highlighting.title && item.highlighting.title[0]) || item.title" />
              </v-card-title>
              <v-card-text v-if="canShowLeadText(item.type)">
                <div
                  v-for="searchableText in item.highlighting.searchable_text"
                  :key="searchableText"
                  v-html="searchableText"
                />
              </v-card-text>
              <!-- eslint-enable vue/no-v-html -->
            </v-card>
          </template>
        </ServersideListing>
      </v-card-text>
    </v-card>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout'
import ServersideListing from '@4tw/ui/lib/components/serverside-data/ServersideListing.vue'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { mapState } from 'vuex'
import { api } from '@/api'
import { createLink } from '@/api/helpers'
import { fromQueryString } from '@4tw/ui/lib/util/query'

const typeIconMap = {
  'backend.meeting': 'mdi-calendar-today',
  'backend.agendaitem': 'mdi-folder',
  'backend.committee': 'mdi-account-group',
  'backend.attachment': 'mdi-file',
  'backend.document': 'mdi-file',
  'backend.proposal': 'mdi-file',
  'backend.protocol': 'mdi-file',
  'backend.committeedocument': 'mdi-file',
  'backend.meetingdocument': 'mdi-file',
}

const typeTitleMap = (vm) => ({
  'backend.meeting': vm.$t('meetings'),
  'backend.agendaitem': vm.$t('agenda_items'),
  'backend.committee': vm.$t('committee'),
  'backend.attachment': vm.$t('Attachments'),
  'backend.document': vm.$t('Documents'),
  'backend.proposal': vm.$t('agenda_item_proposals'),
  'backend.protocol': vm.$t('protocols'),
  'backend.committeedocument': vm.$t('committeeDocuments'),
  'backend.meetingdocument': vm.$t('meetingDocuments'),
})

export default {
  name: 'IndexSuche',
  components: {
    BaseLayout,
    ServersideListing,
  },
  data() {
    return {
      facets: {},
      filter: fromQueryString(this.$route.query, ['query']),
      totalItems: 0,
      items: [],
    }
  },
  computed: {
    ...mapState('application', {
      currentFullPath: (state) => {
        const rootUrl = new URL(state.rootUrl)
        return state.currentFullPath || rootUrl.pathname
      },
    }),
    filterType: {
      get() {
        return this.filter.type || ''
      },
      set(filterType) {
        if (filterType === this.filterType) return
        this.filter = { ...this.filter, type: filterType }
      },
    },
    hasTypes() {
      return !!this.sortedTypes.length
    },
    sortedTypes() {
      if (isEmpty(get(this.facets, 'django_ct', {}))) return []
      return Object.entries(this.facets.django_ct)
        .map(([type, count]) => ({
          type,
          count,
          title: this.getTitleForType(type),
        }))
        .sort((a, b) => a.title.localeCompare(b.title))
    },
  },
  methods: {
    getIconForType(type) {
      return typeIconMap[type] || ''
    },
    getTitleForType(type) {
      return typeTitleMap(this)[type]
    },
    async search(filter) {
      const response = await api.get(createLink('search'), { params: { ...filter } })
      this.facets = response.data.facets
      this.totalItems = response.data.total_items

      return response.data
    },
    isFileType(type) {
      return [
        'backend.attachment',
        'backend.committeedocument',
        'backend.document',
        'backend.meetingdocument',
        'backend.proposal',
        'backend.protocol',
      ].includes(type)
    },
    canShowLeadText(type) {
      return this.isFileType(type)
    },
    openSearchResult(item) {
      const { type, path } = item || {}
      if (!item) {
        this.items = []
        return
      }
      if (type === 'backend.committeedocument') {
        window.open(path, '_blank')
      } else {
        this.$router.push(item)
      }
    },
  },
}
</script>

<style lang="scss">
.search-result {
  em {
    font-style: normal;
    padding: 1px 4px;
    position: relative;

    &::before {
      background: var(--v-primary-base);
      bottom: 0;
      content: '';
      left: 0;
      opacity: 0.2;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
</style>
