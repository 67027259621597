var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(_vm.editMode)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-column px-2 py-1",class:_vm.stateVisibility},[_c('v-switch',{attrs:{"label":_vm.isPublic ? _vm.$t('for_committee') : _vm.$t('only_for_me'),"hint":_vm.$t('visibility'),"persistent-hint":""},model:{value:(_vm.isPublic),callback:function ($$v) {_vm.isPublic=$$v},expression:"isPublic"}}),_c('v-textarea',{directives:[{name:"cmdenter",rawName:"v-cmdenter",value:(_vm.update),expression:"update"}],ref:"textarea",staticClass:"mb-1",attrs:{"auto-grow":"","required":"","hint":_vm.$t('save_shortcut'),"persistent-hint":""},model:{value:(_vm.note.annotation),callback:function ($$v) {_vm.$set(_vm.note, "annotation", $$v)},expression:"note.annotation"}}),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","depressed":"","color":"info"},nativeOn:{"click":function($event){return _vm.update.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('Save')))]),_c('v-btn',{attrs:{"small":"","depressed":"","color":"error"},nativeOn:{"click":function($event){return _vm.save.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('Cancel')))])],1)],1)]):_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-column px-2 py-1 caption",class:_vm.stateVisibility},[_c('div',{staticClass:"d-flex align-center"},[(_vm.isPublic)?[_vm._v(" "+_vm._s(_vm.owner)+" "),_c('span',{staticClass:"px-1"},[_vm._v("·")])]:_vm._e(),(!_vm.note.orphan)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('router-link',_vm._g(_vm._b({attrs:{"to":_vm.note.annotatedObjectURL}},'router-link',attrs,false),on),[_vm._v(" "+_vm._s(_vm.toLocaleDateTimeString(_vm.note.created))+" ")])]}}],null,false,2902488281)},[_c('span',[_vm._v(_vm._s(_vm.$t('Note_in_context')))])]):_c('span',[_vm._v(_vm._s(_vm.toLocaleDateTimeString(_vm.note.created)))]),_c('span',{staticClass:"px-1"},[_vm._v("·")]),(_vm.note.public)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.$t('Committee')))])]}}],null,false,1619417478)},[_c('span',[_vm._v(_vm._s(_vm.$t('hint_visible_for_committee')))])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.$t('Only_me')))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('hint_visible_only_for_me')))])]),_c('v-spacer'),(_vm.note.isOwner)?[(_vm.note.orphan)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){_vm.orphanHelpDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-help-circle")])],1)]}}],null,false,3894198145)},[_c('span',[_vm._v(_vm._s(_vm.$t('this_note_is_outdated')))])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":_vm.edit}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,false,3061448411)},[_c('span',[_vm._v(_vm._s(_vm.$t('Edit')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":_vm.destroy}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v("mdi-delete")])],1)]}}],null,false,2883230393)},[_c('span',[_vm._v(_vm._s(_vm.$t('Remove')))])])]:_vm._e()],2),_c('span',[_vm._v(_vm._s(_vm.note.annotation))])])]),(_vm.note.orphan)?_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.orphanHelpDialog),callback:function ($$v) {_vm.orphanHelpDialog=$$v},expression:"orphanHelpDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('Removed_notes')))]),_c('v-card-text',[_c('p',[_vm._v(" "+_vm._s(_vm.$t('hint_outdated_note'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('hint_copy_outdated_note'))+" ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue","text":""},nativeOn:{"click":function($event){_vm.orphanHelpDialog = false}}},[_vm._v(_vm._s(_vm.$t('close')))])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }