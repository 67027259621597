<template>
  <BaseLayout :breadcrumbs="breadcrumbs">
    <CommitteesSidebar slot="drawer-left" />
    <v-row v-if="committee.canPostRisProposals || committee.can_post_ris_suggestions">
      <v-col v-if="committee.canPostRisProposals" cols="6">
        <TitledCard :title="$t('post_proposal')">
          <p>
            {{ $t('member_can_post_proposals') }}
          </p>
          <RisProposal :committee="committee" @posted="onRisProposalPosted">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" text outlined color="primary" data-testid="create-ris-proposal" v-on="on">
                {{ $t('post_proposal') }}
              </v-btn>
            </template>
          </RisProposal>
        </TitledCard>
      </v-col>
      <v-col v-if="committee.can_post_ris_suggestions" cols="6">
        <TitledCard :title="$t('post_suggestion')">
          <p>
            {{ $t('member_can_post_suggestions') }}
          </p>
          <RisSuggestion :committee="committee" @posted="onRisSuggestionPosted">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" text outlined color="primary" data-testid="create-ris-suggestion" v-on="on">
                {{ $t('post_suggestion') }}
              </v-btn>
            </template>
          </RisSuggestion>
        </TitledCard>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <TitledCard height="100%" data-testid="current-meetings" :title="$t('forthcoming_meetings')">
          <MeetingList :items="upcomingMeetings"></MeetingList>
          <em v-if="!upcomingMeetings.length">
            {{ $t('currently_no_forthcoming_meetings') }}
            <template v-if="committee.can_import_meeting">
              <i18n class="d-inline-block mt-4" path="import_form_description" tag="span" for="link">
                <a href="javascript:;" @click="triggerUploadDialog">{{ $t('form_to_import') }}</a>
              </i18n>
            </template>
          </em>
        </TitledCard>
      </v-col>
      <v-col cols="12" md="6">
        <TitledCard :title="$t('meetingCalendar')" height="100%">
          <v-row>
            <v-col :cols="multipleMeetingsAvailable ? '8' : '12'">
              <v-date-picker
                v-model="date"
                class="elevation-0 mb-2"
                full-width
                landscape
                :locale="$i18n.locale"
                first-day-of-week="1"
                no-title
                :allowed-dates="allowed"
                :events="meetingDates"
                :event-color="eventColor"
                :picker-date.sync="pickerDate"
              ></v-date-picker>
              <span>
                <span class="dot green" />
                <span class="grey--text"> {{ $t('one_meeting') }} </span>
              </span>
              <span class="grey--text">&#x7c; </span>
              <span>
                <span class="dot orange" />
                <span class="grey--text"> {{ $t('multiple_meetings') }}</span>
              </span>
            </v-col>
            <v-col v-if="multipleMeetingsAvailable" cols="4">
              <MeetingList :items="selectedMeetings"></MeetingList>
            </v-col>
          </v-row>
        </TitledCard>
      </v-col>
      <v-col cols="12" md="6">
        <TitledCard :title="$t('basic_documents')">
          <DeleteableDocumentList
            :items="documents"
            :deleteable="!!committee.can_change_documents"
            :empty-message="$t('no_basic_documents_existing')"
            data-testid="grundlagendokumente-list"
          />
          <Upload
            v-if="committee.can_change_documents"
            data-testid="committee-doc-upload-button"
            accept="*"
            file-extension=""
            :title="$t('add_basic_document')"
            :label="$t('basic_document')"
            :error-message="$t('error_on_basic_document_upload')"
            :url="documentUploadURL"
            field="file"
            @canceled="documentName = ''"
            @uploaded="onDocumentUploadSuccess"
            @fileAttached="documentFileAttached"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" text outlined color="primary" class="mt-2" v-on="on">
                {{ $t('add_document') }}
              </v-btn>
            </template>
            <v-text-field v-model="documentName" name="title" :label="$t('Title')"> </v-text-field>
          </Upload>
        </TitledCard>
      </v-col>
      <v-col cols="12" md="6">
        <TitledCard collapsable panel-closed :title="$tc('members', usersCount, { count: usersCount })">
          <template v-if="committee.is_responsible" v-slot:actions>
            <v-btn
              text
              outlined
              color="primary"
              class="mt-2 ml-2"
              :to="{ name: 'committee-edit', params: { id: committee.id } }"
            >
              {{ $t('membership_edit_title') }}
            </v-btn>
          </template>
          <FuzzyList :query="userQuery" @update="updateUsersCount">
            <UserList slot-scope="{ items }" :users="items" />
          </FuzzyList>
        </TitledCard>
        <NotesList class="mt-6" :notes="notes" @create="createNote" />
      </v-col>
    </v-row>
    <Upload
      v-if="committee.can_import_meeting"
      ref="upload"
      accept="application/zip"
      file-extension="zip"
      :title="$t('Import_meeting')"
      :label="$t('zip_file_label')"
      :error-message="$t('meeting_upload_error_msg')"
      :url="meetingUploadURL"
      field="zipfile"
      @uploaded="onMeetingUploadSuccess"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" color="green" dark fixed bottom right fab v-on="on">
          <v-icon>mdi-cloud-upload</v-icon>
        </v-btn>
      </template>
    </Upload>
  </BaseLayout>
</template>

<script>
import path from 'path'
import { DateTime } from 'luxon'
import AgendaItem from '@/store/models/agendaitem'
import Attachment from '@/store/models/attachment'
import Committee from '@/store/models/committee'
import Meeting from '@/store/models/meeting'
import MeetingDocument from '@/store/models/meetingdocument'
import Note from '@/store/models/note'
import User from '@/store/models/user'
import { createLink } from '@/api/helpers'
import { toLocaleDateString, toISOYearMonthString } from '@/filters'
import Document from '@/store/models/document'
import { localeSort } from '@/sorters'
import Profile from '@/store/models/profile'

const uploadMeetingTemplate = '/committees/{committee}/import_meeting'
const uploadDocumentTemplate = '/committees/{committee}/documents'

export default {
  name: 'Committee',
  async initialAsyncData() {
    await Committee.fetchAll()
  },
  async asyncData({ id }) {
    const committee = await Committee.findOrFetch(id)
    // Always replace all notes in the store so we can call Note.all()
    await Note.fetchAllByModel(committee, 'create')
    await Meeting.fetchAllByModel(committee)
    await User.fetchAllByModel(committee, 'create')
    await Document.fetchAllByModel(committee)
  },
  data() {
    return {
      date: null,
      pickerDate: null,
      selectedMeetings: [],
      documentName: '',
      userQuery: User.query().orderBy('fullName'),
      usersCount: null,
    }
  },
  computed: {
    breadcrumbs() {
      const committee = Committee.find(Number.parseInt(this.$route.params.id, 10))
      return [committee]
    },
    committee() {
      return Committee.query().with('meetings').with('documents').find(this.$route.params.id)
    },
    upcomingMeetings() {
      // pastMeetings are ordered by *DESC* start date so limit() gets the most recent
      const pastMeetings = this.committee.pastMeetings.limit(4).all()
      const upcomingMeetings = this.committee.upcomingMeetings.limit(4).all()
      if (pastMeetings.length) {
        const now = DateTime.local()
        // Note: more than one meeting could be in process
        pastMeetings.forEach((m) => {
          if (m.concluded) {
            m.isCurrent = now > DateTime.fromJSDate(m.start) && now < DateTime.fromJSDate(m.concluded)
          } else {
            m.isCurrent = DateTime.fromJSDate(m.start).hasSame(now, 'day')
          }
        })
      }
      if (upcomingMeetings.length) {
        upcomingMeetings[0].isNext = true
      }
      return [...pastMeetings.reverse(), ...upcomingMeetings]
    },
    committeePickerDate() {
      return this.committee.pickerDate()
    },
    notes() {
      /* Usually calling `Note.all()` gets all notes from store.
         But we only want the notes from the active committee.
         Because we fetch the notes using `await Note.fetchAllByModel(committee)`
         the store only contains the notes we want. That's why this is working that way. */
      return Note.ordered.all()
    },
    documents() {
      return localeSort(this.committee.documents, 'title')
    },
    meetingUploadURL() {
      return createLink(uploadMeetingTemplate, { committee: this.committee.id })
    },
    documentUploadURL() {
      return createLink(uploadDocumentTemplate, { committee: this.committee.id })
    },
    meetingDates() {
      return this.committee.meetings.map((meeting) => meeting.ISODateString)
    },
    multipleMeetingsAvailable() {
      return !!this.selectedMeetings.length
    },
    user() {
      return Profile.query().first()
    },
  },
  watch: {
    date(val) {
      this.selectedMeetings = []
      const meetings = this.committee.meetings.filter((m) => m.ISODateString === val)
      if (meetings.length === 1) {
        this.$router.push({
          name: 'meeting',
          params: {
            id: meetings[0].id,
          },
        })
      } else {
        this.selectedMeetings = meetings
      }
    },
    pickerDate() {
      this.selectedMeetings = []
      this.date = null
    },
    $route() {
      this.pickerDate = toISOYearMonthString(new Date())
      this.selectedMeetings = []
    },
  },
  methods: {
    createNote(note) {
      this.committee.createNote(note)
    },
    updateUsersCount(items) {
      this.usersCount = items.length
    },
    onMeetingUploadSuccess() {
      Meeting.fetchAllByModel(this.committee)

      // Clear the storage/cache so we won't display version of meeting documents
      // and attachments which are no longer available in the server-side database.
      // The data will be fetched again anyway when the user opens the meeting view.
      // Also delete stored AgendaItems in order to prevent displaying deleted
      // agenda items of a meeting.
      MeetingDocument.deleteAll()
      Attachment.deleteAll()
      AgendaItem.deleteAll()

      this.notifySuccess(this.$t('meeting_imported'))
    },
    onDocumentUploadSuccess() {
      Document.fetchAllByModel(this.committee)
    },
    allowed(date) {
      return this.meetingDates.includes(date)
    },
    triggerUploadDialog() {
      this.$refs.upload.open()
    },
    toLocaleDateString,
    documentFileAttached(file) {
      if (!this.documentName.length) {
        this.documentName = path.basename(file.name, '.pdf')
      }
    },
    eventColor(date) {
      const meetingsCounts = this.committee.meetings.filter((v) => v.ISODateString === date).length
      if (meetingsCounts === 1) {
        return 'green'
      }
      return 'orange'
    },
    onRisProposalPosted() {
      this.notifySuccess(this.$t('proposal_posted'))
    },
    onRisSuggestionPosted() {
      this.notifySuccess(this.$t('suggestion_posted'))
    },
  },
  head() {
    return {
      title() {
        return { inner: this.committee.title }
      },
    }
  },
}
</script>

<style lang="scss">
.v-picker.v-picker--landscape {
  /*
       There's a css bug in vuetify with the datepicker:
       When "no-title" is set, the margin is still applied altough there's
       no picker__title element.
     */
  .v-picker__body {
    margin-left: 0;
  }

  .v-picker__title + .v-picker__body {
    margin-left: 170px !important;
  }
}

.dot {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
}
</style>
