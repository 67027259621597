import i18n from '@/i18n'
import Versionable from './versionable'
import Annotationable from './mixins/annotationable'

class Proposal extends Versionable {
  static entity = 'proposal'

  static apiPath = 'proposals'

  static primaryKey = 'version_id'

  static fields() {
    return {
      id: this.attr(null),
      file: this.string(''),
      agenda_item_id: this.number(0),
      agenda_item: this.belongsTo('agendaitem', 'agenda_item_id', 'id'),
      created: this.attr('', (v) => new Date(v)),
      updated: this.attr('', (v) => new Date(v)),
      version_id: this.attr(null),
      annotations: this.morphMany('note', 'object_id', 'content_type', 'id'),
      file_extension: this.attr(null),
      has_annotations: this.boolean(false),
      last_seen: this.attr(null),
      annotationLayers: this.morphMany('annotation_layer', 'object_id', 'content_type'),
      selected_layer: this.attr(null),
    }
  }

  get title() {
    /* eslint-disable-next-line class-methods-use-this */
    return i18n.t('agenda_item_proposal')
  }

  get breadcrumbText() {
    return this.title
  }

  get meetingId() {
    return this.agenda_item.meeting_id
  }
}

Object.assign(Proposal.prototype, Annotationable)

export default Proposal
