import axios from 'axios'
import joinPath from 'path.join'

const { VUE_APP_CSRF_COOKIE_NAME, VUE_APP_CSRF_HEADER_NAME } = process.env

axios.defaults.xsrfCookieName = VUE_APP_CSRF_COOKIE_NAME
axios.defaults.xsrfHeaderName = VUE_APP_CSRF_HEADER_NAME

export const api = axios.create({
  baseURL: joinPath(process.env.BASE_URL, process.env.VUE_APP_API_ROOT),
})

export const docInstance = axios.create({
  responseType: 'arraybuffer',
  headers: {
    Accept: 'application/pdf',
  },
})

// Because the django backend likes trailing slashed
// make sure to append at every request
api.interceptors.request.use((request) => {
  const { url } = request
  // eslint-disable-next-line no-param-reassign
  request.url = url.slice(-1) === '/' ? url : `${url}/`
  return request
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error
    if (response.status === 401) {
      const { BASE_URL } = process.env

      const nextURL = window.location.href
      const nextURLQuery = new URLSearchParams({ next: nextURL })

      const loginURL = new URL(window.location.origin)
      loginURL.pathname = joinPath(BASE_URL, 'auth/login/')
      loginURL.search = nextURLQuery.toString()

      window.location = loginURL
    }
    return Promise.reject(error)
  },
)

export function fetchDocument(url) {
  return docInstance.get(url, { withCredentials: true })
}
