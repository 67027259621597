<template>
  <VDialog v-model="show" :persistent="true" :max-width="600" transition="slide-y-reverse-transition">
    <v-card>
      <v-card-title>
        {{ $t('help_dialog_title') }}
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-4">
        <div class="subtitle-2">
          {{ $t('help_dialog_do_you_have_questions') }}
        </div>
        <v-row align="center">
          <v-col class="grow">
            <div class="caption">
              {{ $t('help_dialog_further_information_help_center') }}
            </div>
          </v-col>
          <v-col class="shrink">
            <v-btn href="https://docs.4teamwork.ch/SAP/" target="_blank" small outlined color="primary">
              {{ $t('help_dialog_help_center') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-text class="py-3">
        <component :is="helpComponent" />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn depressed @click="show = false">
          {{ $t('help_dialog_close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </VDialog>
</template>

<script>
export default {
  name: 'HelpDialog',
  data() {
    return {
      show: false,
    }
  },
  computed: {
    helpComponent() {
      let HELP_COMPONENT = process.env.VUE_APP_HELP_COMPONENT
      if (!HELP_COMPONENT) {
        HELP_COMPONENT = 'default'
      }
      return require(`@/components/dialogs/help/${HELP_COMPONENT}.vue`).default
    },
  },
  methods: {
    open() {
      this.show = true
    },
  },
}
</script>
