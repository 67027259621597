import stringFormat from 'string-format'
import i18n from '@/i18n'

function fragFormat(frag) {
  return function format(data) {
    return stringFormat(frag, data)
  }
}

const fragments = {
  meeting: [
    {
      name: i18n.t('Fragment_label_documents_available'),
      text: fragFormat(i18n.t('Fragment_documents_available')),
    },
    {
      name: i18n.t('Fragment_label_meeting_updated'),
      text: fragFormat(i18n.t('Fragment_meeting_updated')),
    },
    {
      name: i18n.t('Fragment_label_meeting_postponed'),
      text: fragFormat(i18n.t('Fragment_meeting_postponed')),
    },
  ],
}

export default fragments
