<template>
  <LinkedListItem :item="item">
    <v-list-item-content>
      <v-list-item-title>
        <span class="text-wrap">{{ item.fullTitle }}</span>
        <v-chip v-if="item.isCurrent" class="primary white--text ml-2" small>{{ $t('current_meeting') }}</v-chip>
        <v-chip v-else-if="item.isNext" class="ml-2" small>{{ $t('next_meeting') }}</v-chip>
      </v-list-item-title>
      <v-list-item-subtitle>
        <time datetime="item.start">
          {{ toLocaleDateTimeString(item.start) }}
        </time>
        <span v-if="item.location">, {{ item.location }}</span>
      </v-list-item-subtitle>
      <v-list-item-subtitle class="text-wrap">
        {{ $t('last_update_on', { date: toLocaleDateTimeString(item.imported_at) }) }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </LinkedListItem>
</template>

<script>
import mixin from '@/components/listitems/mixin'
import { toLocaleDateTimeString } from '@/filters'

export default {
  name: 'MeetingListItem',
  mixins: [mixin],
  methods: {
    toLocaleDateTimeString,
  },
}
</script>

<style scoped>
.v-list__tile__title {
  height: 35px;
}
</style>
