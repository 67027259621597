// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'url-polyfill'

import '@/api'
import Vue from 'vue'
import { sync } from 'vuex-router-sync'
import Vuelidate from 'vuelidate'
import VueHead from 'vue-head'
import App from '@/App'
import router from '@/router'
import rules from '@/rules'
import store from '@/store'
import head from '@/head'
import i18n from '@/i18n'
import '@/directives'
import FtwUI from '@4tw/ui'
import vuetify from './plugins/vuetify'
import '@/global-components'
import '@/mixins'

sync(store, router)
Vue.use(VueHead, head)
Vue.use(Vuelidate)
Vue.use(FtwUI)

Vue.config.productionTip = false
Vue.prototype.$rules = rules

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  i18n,
  vuetify,
  components: { App },
  template: '<App/>',
})
