<template>
  <Sidebar>
    <AttachmentList :items="attachments">
      <template slot="top">
        <BackListItem :title="$t('Back_to_agenda_item')" :parent="agendaitem" :subtitle="agendaitem.title" />
        <v-divider />
        <template v-if="proposal">
          <v-subheader>{{ $t('agenda_item_proposal') }}</v-subheader>
          <LinkedListItem :item="proposal">
            <v-list-item-avatar class="rounded-0">
              <v-icon>{{ `${proposal.mimeTypeIcon}` }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t('agenda_item_proposal_document') }}</v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                {{ $t('last_update_on', { date: toLocaleDateTimeString(proposal.updated) }) }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <ListActionIndicator :item="proposal" />
          </LinkedListItem>
          <v-divider />
          <v-subheader>{{ $t('Attachments') }}</v-subheader>
        </template>
        <v-subheader v-else>{{ $t('Documents') }}</v-subheader>
      </template>
    </AttachmentList>
  </Sidebar>
</template>

<script>
import { toLocaleDateTimeString } from '@/filters'
import Attachment from '@/store/models/attachment'
import mixin from '@/components/sidebars/mixin'

export default {
  name: 'AttachmentSidebar',
  mixins: [mixin],
  props: {
    attachment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    agendaitem() {
      return this.attachment.agenda_item
    },
    proposal() {
      return this.attachment.agenda_item.proposal
    },
    attachments() {
      return Attachment.query().where('agenda_item_id', this.attachment.agenda_item_id).all()
    },
  },
  methods: {
    toLocaleDateTimeString,
  },
}
</script>
