<template>
  <v-card v-if="collapsable">
    <v-expansion-panels :value="open" :style="{ height }">
      <v-expansion-panel class="no-shadow">
        <v-expansion-panel-header class="pl-4">
          <h2 class="headline">
            {{ title }}
          </h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <slot />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card-actions v-if="this.$slots.actions">
      <slot name="actions" />
    </v-card-actions>
  </v-card>
  <v-card v-else :height="height">
    <v-card-title>
      <slot name="title">
        <h2 class="headline">
          {{ title }}
        </h2>
      </slot>
    </v-card-title>
    <v-card-text>
      <slot />
    </v-card-text>
    <v-divider />
    <v-card-actions v-if="this.$slots.actions">
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'TitledCard',
  props: {
    title: {
      type: String,
      default: () => '',
    },
    height: {
      type: String,
      default: () => 'auto',
    },
    collapsable: {
      type: Boolean,
      default: () => false,
    },
    panelClosed: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    open() {
      return this.panelClosed ? -1 : 0
    },
  },
}
</script>

<style scoped>
h2 {
  color: rgba(0, 0, 0, 0.87) !important;
}

.v-expansion-panel__container >>> .v-expansion-panel__header {
  padding-left: 20px;
}

.no-shadow::before {
  box-shadow: none !important;
}
</style>
