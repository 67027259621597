<template>
  <Sidebar>
    <AgendaItemList :scroll-to="scrollTo" :items="agendaitems" :empty-message="$t('no_agenda_items_existing')">
      <template slot="top">
        <BackListItem
          :title="$t('Back_to_meeting')"
          :parent="meeting"
          :subtitle="toLocaleDateTimeString(meeting.start)"
        />
        <v-divider v-if="agendaitems.length" />
      </template>
    </AgendaItemList>
  </Sidebar>
</template>
<script>
import AgendaItem from '@/store/models/agendaitem'
import mixin from '@/components/sidebars/mixin'
import { toLocaleDateTimeString } from '@/filters'

export default {
  name: 'AgendaItemsSidebar',
  mixins: [mixin],
  props: {
    scrollTo: {
      type: Object,
      default: null,
    },
  },
  computed: {
    agendaItem() {
      return AgendaItem.query().with('meeting.agendaitems').find(this.$store.state.route.params.id)
    },
    meeting() {
      return AgendaItem.query().with('meeting').find(this.$route.params.id).meeting
    },
    agendaitems() {
      return AgendaItem.query()
        .with('notes')
        .with('attachments.annotations')
        .with('proposal')
        .with('proposal.annotations')
        .where('meeting_id', this.agendaItem.meeting_id)
        .orderBy('sort_order')
        .all()
    },
  },
  methods: {
    toLocaleDateTimeString,
  },
}
</script>
