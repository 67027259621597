<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <v-form ref="form" v-model="formValid" @submit.prevent="submitShare">
      <v-card>
        <v-card-title>
          <h3 v-if="layer" class="headline">{{ $t('share_modal_title', { layer: layer.name }) }}</h3>
        </v-card-title>
        <v-card-text>
          <h2 class="title">{{ $t('share_subtitle_name') }}</h2>
          <v-text-field
            :rules="[$rules.required]"
            :value="share.display_name"
            data-testid="share-layer-name"
            @input="updateLayerName"
          />
          <h2 class="title mt-4">{{ $t('share_title') }}</h2>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('share_search_label')"
            single-line
            hide-details
          ></v-text-field>
          <v-data-table
            :headers="headers"
            :items="share.users"
            :no-data-text="$t('no_data')"
            :no-results-text="$t('no_data')"
            hide-default-footer
            disable-pagination
            item-key="title"
            :search="search"
            style="max-height: 320px; overflow-y: scroll"
          >
            <template v-slot:item.shared="{ item }">
              <v-simple-checkbox v-model="item.shared" primary />
            </template>
            <template v-slot:item.type="{ item }">
              <v-icon small>{{ getIconForType(item.type) }}</v-icon>
            </template>
          </v-data-table>
          <h2 class="title mt-4">{{ $t('share_subtitle_message') }}</h2>
          <v-checkbox v-model="sendMessage" :label="$t('share_send_message_label')" primary hide-details></v-checkbox>
          <v-textarea v-show="sendMessage" v-model="messageText" :label="$t('share_message_label')"></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn :disabled="isLayerDeletable" color="error" depressed data-testid="share-delete" @click="destroy">
            {{ $t('share_delete') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text data-testid="share-abort" @click="close">
            {{ $t('share_abort') }}
          </v-btn>
          <v-btn
            :disabled="!isValid"
            color="primary"
            :loading="loading"
            depressed
            data-testid="share-activate"
            type="submit"
          >
            {{ $t('share_apply') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import path from 'path'
import AnnotationLayer from '@/store/models/annotation_layer'
import { api } from '@/api'

const typeIconMap = {
  user: 'mdi-account',
  group: 'mdi-account-group',
}

export default {
  name: 'ShareDialog',
  data() {
    return {
      formValid: false,
      search: '',
      loading: false,
      dialog: false,
      share: { users: [] },
      sendMessage: false,
      messageText: '',
      headers: [
        { text: '', align: 'left', value: 'shared', sortable: false, width: '1%' },
        { text: this.$t('share_column_type'), align: 'middle', value: 'type', width: '1%' },
        { text: this.$t('share_column_title'), align: 'left', value: 'title' },
      ],
    }
  },
  computed: {
    layer() {
      return AnnotationLayer.find(this.$route.query.layer_id)
    },
    isValid() {
      return this.share.users.length && this.formValid
    },
    isLayerDeletable() {
      return this.layer && !this.layer.is_private_layer && this.share.users.some((u) => u.shared)
    },
  },
  watch: {
    $route: {
      handler() {
        this.dialog = this.$route.query.share === 'true' || this.$route.query.share === true
        if (this.dialog) {
          this.fetchShare()
        }
      },
      immediate: true,
    },
  },
  methods: {
    getIconForType(type) {
      return typeIconMap[type] || ''
    },
    close() {
      const name = this.$route.name
      const params = this.$route.params
      const query = this.$route.query
      this.sendMessage = false
      this.messageText = ''
      this.$router.push({ name, params, query: { version_id: query.version_id, layer_id: query.layer_id } })
      this.$refs.form.resetValidation()
    },
    updateLayerName(name) {
      this.share.name = name
    },
    async destroy() {
      try {
        await this.layer.destroy()
        this.close()
        this.notifySuccess(this.$t('delete_layer_success_message'))
      } catch (error) {
        if (error.response) {
          return this.notifyError(error.response.data.detail)
        }
        console.error(error)
        return this.notifyError(this.$t('delete_layer_error_message'))
      }
    },
    async fetchShare() {
      const { data } = await api.get(path.join('v1', 'annotationlayers', this.$route.query.layer_id.toString()))
      this.share = data
    },
    async submitShare() {
      this.loading = true
      try {
        await api.post(path.join('v1', 'annotationlayers', this.$route.query.layer_id.toString(), 'share'), {
          users: this.share.users.filter((principal) => principal.shared),
          name: this.share.name,
          send_message: this.sendMessage,
          text: this.messageText,
          href: window.location.href,
        })
        this.notifySuccess(this.$t('share_success_message'))
      } catch (e) {
        this.notifyError(this.$t('share_error_message'))
      } finally {
        this.close()
        this.loading = false
      }
    },
  },
}
</script>
