<template>
  <v-navigation-drawer v-model="versionsSidebar" touchless fixed app right clipped width="376">
    <div class="d-flex flex-column fill-height">
      <DividedList
        data-testid="version-list"
        :items="versionable.versions"
        :empty-message="$t('no_versions_exist')"
        :list-item="VersionListItem"
      >
        <template slot="top">
          <v-list-item v-if="nextAgendaItem.link" :to="nextAgendaItem.link">
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('next_agenda_item') }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                {{ nextAgendaItem.title }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
        </template>
      </DividedList>
      <v-spacer />
      <v-btn medium icon @click.stop="toggleVersionsSidebar">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import joinPath from 'path.join'
import { api } from '@/api'
import { createLink } from '@/api/helpers'
import VersionListItem from '@/components/listitems/VersionListItem'

export default {
  name: 'VersionsSidebar',
  props: {
    versionable: {
      type: Object,
      default: () => {},
    },
    showNextAgendaItemLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      VersionListItem,
      nextAgendaItem: {
        link: '#',
        title: 'Lade…',
      },
    }
  },
  computed: {
    ...mapGetters({
      getVersionsSidebar: 'application/getVersionsSidebar',
    }),
    versionsSidebar: {
      set(versionsSidebar) {
        this.setVersionsSidebar({ versionsSidebar })
      },
      get() {
        return this.getVersionsSidebar
      },
    },
  },
  watch: {
    $route: {
      handler() {
        this.fetchNextAgendaItemLink()
      },
      immediate: true,
    },
  },
  methods: {
    async fetchNextAgendaItemLink() {
      if (!this.showNextAgendaItemLink) {
        this.nextAgendaItem = { link: null }
        return
      }

      try {
        const response = await api.get(createLink(joinPath(this.versionable.idPath, 'next_agenda_item')))
        this.nextAgendaItem = response.data
      } catch (e) {
        this.nextAgendaItem = { link: null }
        throw e
      }
    },
    ...mapActions({
      setVersionsSidebar: 'application/setVersionsSidebar',
      toggleVersionsSidebar: 'application/toggleVersionsSidebar',
    }),
  },
}
</script>

<style lang="scss" scoped>
/* Prevent (inconsistent) thicker border on top of active group item. */
::v-deep .v-list__group--active:before {
  background: none !important;
}
</style>
