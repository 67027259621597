<template>
  <v-row>
    <v-col v-if="editMode" cols="12">
      <div class="d-flex flex-column px-2 py-1" :class="stateVisibility">
        <v-switch
          v-model="isPublic"
          :label="isPublic ? $t('for_committee') : $t('only_for_me')"
          :hint="$t('visibility')"
          persistent-hint
        />
        <v-textarea
          ref="textarea"
          v-model="note.annotation"
          v-cmdenter="update"
          class="mb-1"
          auto-grow
          required
          :hint="$t('save_shortcut')"
          persistent-hint
        ></v-textarea>
        <div class="d-flex">
          <v-btn small depressed color="info" class="mr-1" @click.native="update">{{ $t('Save') }}</v-btn>
          <v-btn small depressed color="error" @click.native="save">{{ $t('Cancel') }}</v-btn>
        </div>
      </div>
    </v-col>
    <v-col v-else cols="12">
      <div :class="stateVisibility" class="d-flex flex-column px-2 py-1 caption">
        <div class="d-flex align-center">
          <template v-if="isPublic">
            {{ owner }}
            <span class="px-1">·</span>
          </template>
          <v-tooltip v-if="!note.orphan" top>
            <template v-slot:activator="{ on, attrs }">
              <router-link v-bind="attrs" :to="note.annotatedObjectURL" v-on="on">
                {{ toLocaleDateTimeString(note.created) }}
              </router-link>
            </template>
            <span>{{ $t('Note_in_context') }}</span>
          </v-tooltip>
          <span v-else>{{ toLocaleDateTimeString(note.created) }}</span>
          <span class="px-1">·</span>
          <v-tooltip v-if="note.public" top>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ $t('Committee') }}</span>
            </template>
            <span>{{ $t('hint_visible_for_committee') }}</span>
          </v-tooltip>
          <v-tooltip v-else top>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ $t('Only_me') }}</span>
            </template>
            <span>{{ $t('hint_visible_only_for_me') }}</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <template v-if="note.isOwner">
            <v-tooltip v-if="note.orphan" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" small icon v-on="on" @click="orphanHelpDialog = true">
                  <v-icon small>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('this_note_is_outdated') }}</span>
            </v-tooltip>
            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" small icon v-on="on" @click="edit">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Edit') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" icon small v-on="on" @click="destroy">
                  <v-icon small color="red">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Remove') }}</span>
            </v-tooltip>
          </template>
        </div>
        <span>{{ note.annotation }}</span>
      </div>
    </v-col>
    <v-dialog v-if="note.orphan" v-model="orphanHelpDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">{{ $t('Removed_notes') }}</v-card-title>
        <v-card-text>
          <p>
            {{ $t('hint_outdated_note') }}
          </p>
          <p>
            {{ $t('hint_copy_outdated_note') }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" text @click.native="orphanHelpDialog = false">{{ $t('close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import get from 'lodash/get'
import { toLocaleDateTimeString } from '@/filters'
import NoteMixin from './mixin'

export default {
  name: 'Note',
  mixins: [NoteMixin],
  props: {
    note: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      orphanHelpDialog: false,
      editMode: false,
      isPublic: this.note.public,
    }
  },
  computed: {
    owner() {
      const fullNameArr = [get(this.note, 'author.first_name'), get(this.note, 'author.last_name')].filter((i) => !!i)
      if (fullNameArr.length) {
        return `${fullNameArr.join(' ')} (${get(this.note, 'author.username')})`
      }

      return get(this.note, 'author.username')
    },
  },
  methods: {
    toLocaleDateTimeString,
    edit() {
      this.editMode = true
      this.$nextTick(() => {
        this.$refs.textarea.$el.querySelector('textarea').focus()
      })
    },
    save() {
      this.editMode = false
    },
    async destroy() {
      await this.note.destroy()
    },
    async update() {
      this.note.public = this.isPublic
      await this.note.update(['annotation', 'public'])
      this.save()
    },
  },
}
</script>
<style scoped>
.note-content {
  white-space: pre-line;
}
</style>
