<template>
  <v-app-bar class="primary elevation-0" app dark clipped-left clipped-right>
    <v-app-bar-nav-icon @click.stop="toggleDrawer"></v-app-bar-nav-icon>
    <LiveSearch v-if="hasSolr" class="mr-4" />
    <slot />
    <v-spacer></v-spacer>
    <slot name="actions" />
    <v-menu offset-y bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" icon text data-testid="personal-menu" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <template v-if="user.displayName">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ user.displayName }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
        </template>
        <v-list-item
          v-for="(name, lang_code) in supportedLanguages"
          :key="lang_code"
          @click="switchLanguage(lang_code)"
        >
          <v-list-item-avatar size="24">
            <v-icon>mdi-web</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ name }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-avatar v-if="currentLanguage() === lang_code" size="20">
            <v-icon>mdi-check</v-icon>
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-avatar size="24">
            <v-icon>mdi-power</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ $t('Logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapActions } from 'vuex'
import { currentLanguage, supportedLanguages, switchLanguage } from '@/i18n'
import joinPath from 'path.join'
import Profile from '@/store/models/profile'

const hasSolr = process.env.VUE_APP_SOLR_URL

export default {
  name: 'Toolbar',
  data() {
    return {
      hasSolr,
      supportedLanguages,
    }
  },
  computed: {
    user() {
      return Profile.query().first()
    },
  },
  methods: {
    ...mapActions({
      toggleDrawer: 'application/toggleDrawer',
    }),
    currentLanguage,
    switchLanguage,
    logout() {
      const { BASE_URL } = process.env

      const nextURL = window.location.pathname
      const nextURLQuery = new URLSearchParams({ next: nextURL })

      const loginURL = new URL(window.location.origin)
      loginURL.pathname = joinPath(BASE_URL, 'auth/logout/')
      loginURL.search = nextURLQuery.toString()

      window.location = loginURL
    },
  },
}
</script>
