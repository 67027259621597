var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseLayout',{attrs:{"narrow":""}},[_c('CommitteesSidebar',{attrs:{"slot":"drawer-left"},slot:"drawer-left"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('TitledCard',{attrs:{"title":_vm.$t('membership_edit_title')}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('membership_help_text'))+" ")]),_c('v-list',{attrs:{"data-testid":"membership-list"}},_vm._l((_vm.memberships),function(membership){return _c('v-list-item',{key:membership.id,attrs:{"ripple":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(membership.last_name)+" "+_vm._s(membership.first_name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(membership.roles_display)+" "),(_vm.committee.members_can_see_all_meetings && (membership.begin_date || membership.end_date))?_c('span',[_vm._v(" ("+_vm._s(_vm.toLocaleDateString(membership.begin_date))+" - "+_vm._s(_vm.toLocaleDateString(membership.end_date))+") ")]):_vm._e()])],1),_c('v-list-item-action',[_c('div',{staticClass:"d-flex"},[_c('MembershipEditDialog',{attrs:{"membership":membership,"committee":_vm.committee},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)}),_c('v-btn',{attrs:{"icon":"","data-testid":"delete-membership"}},[_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.removeMembership(membership)}}},[_vm._v("mdi-delete")])],1)],1)])],1)}),1),_c('MembershipEditDialog',{attrs:{"membership":_vm.newMembership,"committee":_vm.committee},scopedSlots:_vm._u([{key:"person",fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.users,"item-value":"id","item-text":"fullName","label":_vm.$t('share_subtitle_name')},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.fullName)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.email)}})],1)]}}]),model:{value:(_vm.newMembership.user),callback:function ($$v) {_vm.$set(_vm.newMembership, "user", $$v)},expression:"newMembership.user"}})]},proxy:true},{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","outlined":"","color":"primary","data-testid":"add-membership"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Add'))+" ")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }