import Versionable from './versionable'
import Annotationable from './mixins/annotationable'

class MeetingDocument extends Versionable {
  static entity = 'meetingdocument'

  static apiPath = 'meeting_documents'

  static primaryKey = 'version_id'

  static fields() {
    return {
      id: this.attr(null),
      title: this.string(''),
      file: this.string(''),
      created: this.attr('', (v) => new Date(v)),
      updated: this.attr('', (v) => new Date(v)),
      meeting_id: this.number(0),
      meeting: this.belongsTo('meeting', 'meeting_id', 'id'),
      version_id: this.attr(null),
      annotation: this.attr(null),
      file_extension: this.attr(null),
      has_annotations: this.boolean(false),
      last_seen: this.attr(null),
      annotationLayers: this.morphMany('annotation_layer', 'object_id', 'content_type'),
      selected_layer: this.attr(null),
    }
  }

  get breadcrumbText() {
    return this.title
  }

  get meetingId() {
    return this.meeting_id
  }
}

Object.assign(MeetingDocument.prototype, Annotationable)

export default MeetingDocument
