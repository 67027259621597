<template>
  <BaseLayout narrow>
    <MeetingDocumentSidebar slot="drawer-left" :meeting-document="documentVersion" />
    <PdfViewerActions slot="toolbar-actions" :document="documentVersion" />
    <PdfViewer v-if="hasVersionId" :annotate-on="documentVersion">
      <Download :url="documentVersion.file" />
    </PdfViewer>
    <VersionsSidebar slot="drawer-right" :versionable="documentVersion" />
  </BaseLayout>
</template>

<script>
import MeetingDocument from '@/store/models/meetingdocument'
import Meeting from '@/store/models/meeting'
import documentMixin from '@/components/pages/mixins/document'

export default {
  name: 'MeetingDocument',
  mixins: [documentMixin],
  async asyncData({ id, version_id: versionId }) {
    const meetingDocument = await MeetingDocument.fetchVersion(id, versionId)
    const [versions, meeting] = await Promise.all([
      meetingDocument.fetchVersions(),
      Meeting.findOrFetch(meetingDocument.meeting_id),
    ])
    await Promise.all([...versions.map((v) => v.fetchAnnotationLayers()), MeetingDocument.fetchAllByModel(meeting)])
  },
  data() {
    return {
      documentType: 'meetingdocument',
    }
  },
  computed: {
    document() {
      return MeetingDocument.query().with('meeting')
    },
  },
}
</script>
