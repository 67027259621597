import { getField, updateField } from 'vuex-map-fields'

const state = {
  title: '',
  description: '',
  businessType: {},
  isUrgent: false,
  hasSignatureSheet: false,
}

const mutations = {
  updateField,
  reset(s) {
    s.title = ''
    s.description = ''
    s.businessType = {}
    s.isUrgent = false
    s.hasSignatureSheet = false
  },
}

const actions = {
  reset({ commit }) {
    commit('reset')
  },
}

const getters = {
  getField,
}

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true,
}
